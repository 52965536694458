import { ReactComponent as Snake } from "src/assets/team/snake.svg";
import { ReactComponent as Shark } from "src/assets/team/shark.svg";
import { ReactComponent as Roach } from "src/assets/team/roach.svg";
import { ReactComponent as Ape } from "src/assets/team/ape.svg";
import { ReactComponent as Doge } from "src/assets/team/doge.svg";
import "./Team.styles.scss";
import { Social } from "src/ui/components";
import {
  faInstagram,
  faReddit,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";

export default function Team() {
  return (
    <div className="team" data-testid="team">
      <div className="member">
        <div className="teamAvatar member1" />
        <div className="teammateDetails">
          <div className="teammateName">Jonny D</div>
          <div className="teammateSocials">
            <div>
              <Social icon={faXTwitter} href="/" size="2x" />
            </div>
            <div>
              <Social icon={faInstagram} href="/" size="2x" />
            </div>
            <div>
              <Social icon={faReddit} href="/" size="2x" />
            </div>
          </div>
        </div>
      </div>
      <div className="member">
        <div className="teamAvatar member2" />
        <div className="teammateDetails">
          <div className="teammateName">Nodens</div>
          <div className="teammateSocials">
            <div>
              <Social icon={faXTwitter} href="/" size="2x" />
            </div>
            <div>
              <Social icon={faInstagram} href="/" size="2x" />
            </div>
            <div>
              <Social icon={faReddit} href="/" size="2x" />
            </div>
          </div>
        </div>
      </div>
      <div className="member">
        <div className="teamAvatar member3" />
        <div className="teammateDetails">
          <div className="teammateName">Cyber Alchemist</div>
          <div className="teammateSocials">
            <div>
              <Social icon={faXTwitter} href="/" size="2x" />
            </div>
            <div>
              <Social icon={faInstagram} href="/" size="2x" />
            </div>
            <div>
              <Social icon={faReddit} href="/" size="2x" />
            </div>
          </div>
        </div>
      </div>
      <div className="member">
        <div className="teamAvatar member4" />
        <div className="teammateDetails">
          <div className="teammateName">K00K</div>
          <div className="teammateSocials">
            <div>
              <Social icon={faXTwitter} href="/" size="2x" />
            </div>
            <div>
              <Social icon={faInstagram} href="/" size="2x" />
            </div>
            <div>
              <Social icon={faReddit} href="/" size="2x" />
            </div>
          </div>
        </div>
      </div>
      <div className="member">
        <div className="teamAvatar member5" />
        <div className="teammateDetails">
          <div className="teammateName">Gary V</div>
          <div className="teammateSocials">
            <div>
              <Social icon={faXTwitter} href="/" size="2x" />
            </div>
            <div>
              <Social icon={faInstagram} href="/" size="2x" />
            </div>
            <div>
              <Social icon={faReddit} href="/" size="2x" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
