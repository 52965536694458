import PageProps from "./Page.types";

import { Top, Main, Bottom } from "./components";
import TopProps from "./components/Top/Top.types";
import MainProps from "./components/Main/Main.types";
import BottomProps from "./components/Bottom/Bottom.types";
import "./Page.styles.scss";

function Page({ children, classNames }: PageProps) {
  const className = classNames ? `page ${classNames}` : `page`;
  return (
    <div className={className} data-testid="page">
      {children}
    </div>
  );
}

Page.Top = function ({ children }: TopProps) {
  return <Top>{children}</Top>;
};

Page.Main = function ({ children }: MainProps) {
  return <Main>{children}</Main>;
};

Page.Bottom = function ({ children }: BottomProps) {
  return <Bottom>{children}</Bottom>;
};

export default Page;
