import Ticker from "../../Ticker";
import "./Five.styles.scss";

export default function Five() {
  return (
    <div id="section3">
      <Ticker classNames="joinnow">
        <div className="splashTick">
          <span>DUCKBOI DEGEN DUNGEON CULT</span>
        </div>
        <div className="splashTick">
          <span>JOIN NOW</span>
        </div>
        <div className="splashTick">
          <span>DUCKBOI DEGEN DUNGEON CULT</span>
        </div>
        <div className="splashTick">
          <span>JOIN NOW</span>
        </div>
        <div className="splashTick">
          <span>DUCKBOI DEGEN DUNGEON CULT</span>
        </div>
        <div className="splashTick">
          <span>JOIN NOW</span>
        </div>
      </Ticker>
    </div>
  );
}
