import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Breadcrumbs.styles.scss";
import { Icons } from "src/ui/features";

function BreadCrumb({
  crumb,
  currentLink,
}: {
  crumb: string;
  currentLink: string;
}) {
  return (
    <div className="breadcrumb">
      <Link to={currentLink}>{crumb}</Link>
    </div>
  );
}

export default function Breadcrumbs() {
  const location = useLocation();
  const navigate = useNavigate();
  const breadCrumbs = location.pathname
    .split("/")
    .filter((crumb: string) => crumb !== "");

  let currentLink = "";

  return location.pathname !== "/" ? (
    <div className="breadcrumbs" data-testid="breadcrumbs" key="breadcrumbs">
      <Icons.BackArrow callback={() => navigate(-1)} />
      {breadCrumbs.map((crumb: string) => {
        currentLink += `/${crumb}`;
        return (
          <BreadCrumb key={crumb} crumb={crumb} currentLink={currentLink} />
        );
      })}
    </div>
  ) : (
    <div />
  );
}
