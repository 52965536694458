import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "./customFetchBase";
import { IMember } from "src/types/IMember.types";
import { JoinNowPayloadType } from "src/ui/features/Forms/JoinNow/JoinNow.schema";

export const contactApi = createApi({
  reducerPath: "contactApi",
  baseQuery: customFetchBase,
  tagTypes: ["Contact"],
  endpoints: (builder) => ({
    contact: builder.mutation<IMember, JoinNowPayloadType>({
      query(data) {
        return {
          url: "comms/contact",
          method: "POST",
          body: data,
        };
      },
    }),
  }),
});

export const { useContactMutation } = contactApi;
