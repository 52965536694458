import { ReactComponent as AxeMurderer } from "src/assets/memes/monograms/axemurderer.svg";
import { ReactComponent as Brat } from "src/assets/memes/monograms/brat.svg";
import { ReactComponent as Bullish } from "src/assets/memes/monograms/bullish.svg";
import { ReactComponent as Droog } from "src/assets/memes/monograms/droog.svg";
import { ReactComponent as FalseIdol } from "src/assets/memes/monograms/falseidol.svg";
import { ReactComponent as FastFood } from "src/assets/memes/monograms/fastfood.svg";
import { ReactComponent as GameBoy } from "src/assets/memes/monograms/gameboy.svg";
import { ReactComponent as Grunge } from "src/assets/memes/monograms/grunge.svg";
import { ReactComponent as Hoodlum } from "src/assets/memes/monograms/hoodlum.svg";
import { ReactComponent as Jerkoff } from "src/assets/memes/monograms/jerkoff.svg";
import { ReactComponent as Looney } from "src/assets/memes/monograms/looney.svg";
import { ReactComponent as MetalHead } from "src/assets/memes/monograms/metalhead.svg";
import { ReactComponent as MovieBuff } from "src/assets/memes/monograms/moviebuff.svg";
import { ReactComponent as Og } from "src/assets/memes/monograms/og.svg";
import { ReactComponent as Popcat } from "src/assets/memes/monograms/popcat.svg";
import { ReactComponent as Punkass } from "src/assets/memes/monograms/punkass.svg";
import { ReactComponent as Redneck } from "src/assets/memes/monograms/redneck.svg";
import { ReactComponent as SkateRat } from "src/assets/memes/monograms/skaterat.svg";
import { ReactComponent as Slasher } from "src/assets/memes/monograms/slasher.svg";
import { ReactComponent as SweetTooth } from "src/assets/memes/monograms/sweettooth.svg";
import { ReactComponent as Spoiled } from "src/assets/memes/monograms/spoiled.svg";
import { ReactComponent as Trash } from "src/assets/memes/monograms/trash.svg";
import { SplashTheme } from "src/types/IUi.types";

export default function Monogram({ splashTheme }: { splashTheme: string }) {
  return (
    <>
      {splashTheme === SplashTheme.AXE_MURDERER && <AxeMurderer />}
      {splashTheme === SplashTheme.BRAT && <Brat />}
      {splashTheme === SplashTheme.BULLISH && <Bullish />}
      {splashTheme === SplashTheme.DROOG && <Droog />}
      {splashTheme === SplashTheme.FALSE_IDOL && <FalseIdol />}
      {splashTheme === SplashTheme.FASTFOODJUNKY && <FastFood />}
      {splashTheme === SplashTheme.GAME_BOY && <GameBoy />}
      {splashTheme === SplashTheme.GRUNGE && <Grunge />}
      {splashTheme === SplashTheme.HOODLUM && <Hoodlum />}
      {splashTheme === SplashTheme.JERKOFF && <Jerkoff />}
      {splashTheme === SplashTheme.LOONEY && <Looney />}
      {splashTheme === SplashTheme.METALHEAD && <MetalHead />}
      {splashTheme === SplashTheme.MOVIE_BUFF && <MovieBuff />}
      {splashTheme === SplashTheme.OG && <Og />}
      {splashTheme === SplashTheme.POPCAT && <Popcat />}
      {splashTheme === SplashTheme.PUNKASS && <Punkass />}
      {splashTheme === SplashTheme.REDNECK && <Redneck />}
      {splashTheme === SplashTheme.SKATERAT && <SkateRat />}
      {splashTheme === SplashTheme.SLASHER && <Slasher />}
      {splashTheme === SplashTheme.SWEETTOOTH && <SweetTooth />}
      {splashTheme === SplashTheme.SPOILED && <Spoiled />}
      {splashTheme === SplashTheme.TRASH && <Trash />}
    </>
  );
}
