import { ReactComponent as Duckboi1 } from "src/assets/memes/duckbois/axeMurderer.svg";
import { ReactComponent as Duckboi2 } from "src/assets/memes/duckbois/bullish.svg";
import { ReactComponent as Duckboi3 } from "src/assets/memes/duckbois/droog.svg";
import { ReactComponent as Duckboi4 } from "src/assets/memes/duckbois/falseidol.svg";
import { ReactComponent as Duckboi5 } from "src/assets/memes/duckbois/fastfoodJunky.svg";
import { ReactComponent as Duckboi6 } from "src/assets/memes/duckbois/gameboi.svg";
import { ReactComponent as Duckboi7 } from "src/assets/memes/duckbois/grunge.svg";
import { ReactComponent as Duckboi8 } from "src/assets/memes/duckbois/hoodlum.svg";
import { ReactComponent as Duckboi9 } from "src/assets/memes/duckbois/jerkoff.svg";
import { ReactComponent as Duckboi10 } from "src/assets/memes/duckbois/loon.svg";
import { ReactComponent as Duckboi11 } from "src/assets/memes/duckbois/metalhead.svg";
import { ReactComponent as Duckboi12 } from "src/assets/memes/duckbois/moviebuff.svg";
import { ReactComponent as Duckboi13 } from "src/assets/memes/duckbois/og.svg";
import { ReactComponent as Duckboi14 } from "src/assets/memes/duckbois/popcat.svg";
import { ReactComponent as Duckboi15 } from "src/assets/memes/duckbois/punkass.svg";
import { ReactComponent as Duckboi16 } from "src/assets/memes/duckbois/redneck.svg";
import { ReactComponent as Duckboi17 } from "src/assets/memes/duckbois/skaterat.svg";
import { ReactComponent as Duckboi18 } from "src/assets/memes/duckbois/dreamSlasher.svg";
import { ReactComponent as Duckboi19 } from "src/assets/memes/duckbois/brat.svg";
import { ReactComponent as Duckboi20 } from "src/assets/memes/duckbois/sweettooth.svg";
import { ReactComponent as Duckboi21 } from "src/assets/memes/duckbois/spoiled.svg";
import { ReactComponent as Duckboi22 } from "src/assets/memes/duckbois/trash.svg";
import "./Slider.styles.scss";

export default function Slider() {
  return (
    <div className="splashSlider">
      <div className="splashSlides">
        <div className="splashSlideWrapper">
          <Duckboi1 className="splashSlide" />
          <div className="slideBg" />
        </div>
        <div className="splashSlideWrapper">
          <Duckboi2 className="splashSlide" />
          <div className="slideBg" />
        </div>
        <div className="splashSlideWrapper">
          <Duckboi3 className="splashSlide" />
          <div className="slideBg" />
        </div>
        <div className="splashSlideWrapper">
          <Duckboi4 className="splashSlide" />
          <div className="slideBg" />
        </div>
        <div className="splashSlideWrapper">
          <Duckboi5 className="splashSlide" />
          <div className="slideBg" />
        </div>
        <div className="splashSlideWrapper">
          <Duckboi6 className="splashSlide" />
          <div className="slideBg" />
        </div>
        <div className="splashSlideWrapper">
          <Duckboi7 className="splashSlide" />
          <div className="slideBg" />
        </div>
        <div className="splashSlideWrapper">
          <Duckboi8 className="splashSlide" />
          <div className="slideBg" />
        </div>
        <div className="splashSlideWrapper">
          <Duckboi9 className="splashSlide" />
          <div className="slideBg" />
        </div>
        <div className="splashSlideWrapper">
          <Duckboi10 className="splashSlide" />
          <div className="slideBg" />
        </div>
        <div className="splashSlideWrapper">
          <Duckboi11 className="splashSlide" />
          <div className="slideBg" />
        </div>
        <div className="splashSlideWrapper">
          <Duckboi12 className="splashSlide" />
          <div className="slideBg" />
        </div>
        <div className="splashSlideWrapper">
          <Duckboi13 className="splashSlide" />
          <div className="slideBg" />
        </div>
        <div className="splashSlideWrapper">
          <Duckboi14 className="splashSlide" />
          <div className="slideBg" />
        </div>
        <div className="splashSlideWrapper">
          <Duckboi15 className="splashSlide" />
          <div className="slideBg" />
        </div>
        <div className="splashSlideWrapper">
          <Duckboi16 className="splashSlide" />
          <div className="slideBg" />
        </div>
        <div className="splashSlideWrapper">
          <Duckboi17 className="splashSlide" />
          <div className="slideBg" />
        </div>
        <div className="splashSlideWrapper">
          <Duckboi18 className="splashSlide" />
          <div className="slideBg" />
        </div>
        <div className="splashSlideWrapper">
          <Duckboi19 className="splashSlide" />
          <div className="slideBg" />
        </div>
        <div className="splashSlideWrapper">
          <Duckboi20 className="splashSlide" />
          <div className="slideBg" />
        </div>
        <div className="splashSlideWrapper">
          <Duckboi21 className="splashSlide" />
          <div className="slideBg" />
        </div>
        <div className="splashSlideWrapper">
          <Duckboi22 className="splashSlide" />
          <div className="slideBg" />
        </div>
      </div>
      <div className="splashSlides">
        <div className="splashSlideWrapper">
          <Duckboi1 className="splashSlide" />
          <div className="slideBg" />
        </div>
        <div className="splashSlideWrapper">
          <Duckboi2 className="splashSlide" />
          <div className="slideBg" />
        </div>
        <div className="splashSlideWrapper">
          <Duckboi3 className="splashSlide" />
          <div className="slideBg" />
        </div>
        <div className="splashSlideWrapper">
          <Duckboi4 className="splashSlide" />
          <div className="slideBg" />
        </div>
        <div className="splashSlideWrapper">
          <Duckboi5 className="splashSlide" />
          <div className="slideBg" />
        </div>
        <div className="splashSlideWrapper">
          <Duckboi6 className="splashSlide" />
          <div className="slideBg" />
        </div>
        <div className="splashSlideWrapper">
          <Duckboi7 className="splashSlide" />
          <div className="slideBg" />
        </div>
        <div className="splashSlideWrapper">
          <Duckboi8 className="splashSlide" />
          <div className="slideBg" />
        </div>
        <div className="splashSlideWrapper">
          <Duckboi9 className="splashSlide" />
          <div className="slideBg" />
        </div>
        <div className="splashSlideWrapper">
          <Duckboi10 className="splashSlide" />
          <div className="slideBg" />
        </div>
        <div className="splashSlideWrapper">
          <Duckboi11 className="splashSlide" />
          <div className="slideBg" />
        </div>
        <div className="splashSlideWrapper">
          <Duckboi12 className="splashSlide" />
          <div className="slideBg" />
        </div>
        <div className="splashSlideWrapper">
          <Duckboi13 className="splashSlide" />
          <div className="slideBg" />
        </div>
        <div className="splashSlideWrapper">
          <Duckboi14 className="splashSlide" />
          <div className="slideBg" />
        </div>
        <div className="splashSlideWrapper">
          <Duckboi15 className="splashSlide" />
          <div className="slideBg" />
        </div>
        <div className="splashSlideWrapper">
          <Duckboi16 className="splashSlide" />
          <div className="slideBg" />
        </div>
        <div className="splashSlideWrapper">
          <Duckboi17 className="splashSlide" />
          <div className="slideBg" />
        </div>
        <div className="splashSlideWrapper">
          <Duckboi18 className="splashSlide" />
          <div className="slideBg" />
        </div>
        <div className="splashSlideWrapper">
          <Duckboi19 className="splashSlide" />
          <div className="slideBg" />
        </div>
        <div className="splashSlideWrapper">
          <Duckboi20 className="splashSlide" />
          <div className="slideBg" />
        </div>
        <div className="splashSlideWrapper">
          <Duckboi21 className="splashSlide" />
          <div className="slideBg" />
        </div>
        <div className="splashSlideWrapper">
          <Duckboi22 className="splashSlide" />
          <div className="slideBg" />
        </div>
      </div>
    </div>
  );
}
