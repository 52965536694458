import { ContactModalProps } from "./Contact.types";
import { Forms } from "src/ui/features";
import { Modal } from "src/ui/components";
import "./Contact.styles.scss";

export default function ContactModal({
  contactIcon,
  handleShowContactModal,
  showModal,
  size,
}: ContactModalProps) {
  return showModal ? (
    <Modal classNames="contactModal" data-testid="contactModal">
      <Modal.Header
        handleClick={handleShowContactModal}
        icon={contactIcon}
        size={size}
      ></Modal.Header>
      <Modal.Body>
        <Forms.ContactForm closeModal={handleShowContactModal} />
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  ) : null;
}
