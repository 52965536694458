import { useEffect, useState } from "react";

export default function usePager(data: any, offset: number) {
  const [current, setCurrent] = useState(1);
  const [paged, setPaged] = useState([]);

  useEffect(() => {
    setPaged(data.slice(current - 1, offset));
  }, [current, data, offset]);

  function next() {
    if (current < data.length) setCurrent(current + 1);
  }

  function prev() {
    if (current > 1) {
      setCurrent(current - 1);
    }
  }

  return {
    paged,
    next,
    prev,
  };
}
