import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUiState } from "../types";

const initialState: IUiState = {
  theme: "dark-theme",
  editUser: false,
  splashTheme: "og",
};

export const uiSlice = createSlice({
  initialState,
  name: "ui",
  reducers: {
    toggleEditUser: (state) => {
      state.editUser = state.editUser === true ? false : true;
    },
    toggleTheme: (state) => {
      state.theme = state.theme === "dark-theme" ? "light-theme" : "dark-theme";
    },
    setSplashTheme: (state, action: PayloadAction<string>) => {
      state.splashTheme = action.payload;
    },
  },
});

export const { setSplashTheme, toggleEditUser, toggleTheme } = uiSlice.actions;

export default uiSlice.reducer;
