import PageTitleProps from "./PageTitle.types";
import "./PageTitle.styles.scss";

export default function PageTitle({ children, ...props }: PageTitleProps) {
  const { pageTitle, classNames } = props;
  const className = `pageTitle ${classNames}`;
  return pageTitle ? (
    <div className={className} data-testid="pageTitle">
      <h1>{pageTitle}</h1>
      {children}
    </div>
  ) : null;
}
