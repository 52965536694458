import { useAuth } from "src/hooks";
import ProfileMenuItem from "./components";
import IconMenuItemProps from "./components/IconMenuItem/IconMenuItem.types";
import "./IconMenu.styles.scss";

export default function IconMenu({
  menuItems,
  role,
  title,
}: {
  menuItems: IconMenuItemProps[];
  role: string;
  title: string;
}) {
  const { hasPermission } = useAuth([role]);
  return hasPermission() ? (
    <div className="menu profile" data-testid="profile">
      <h3>{title}</h3>
      <div className="iconMenu">
        {menuItems.map(({ icon, text, to }, index) => (
          <ProfileMenuItem key={index} icon={icon} text={text} to={to} />
        ))}
      </div>
    </div>
  ) : null;
}
