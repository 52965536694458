import { useForgotPasswordMutation } from "src/redux/api/auth.api";
import { Redirect } from "src/hooks";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export default function useForgotPassword() {
  const navigate = useNavigate();
  const [forgotPassword, { data, isSuccess }] = useForgotPasswordMutation();
  const { email } = data || {};
  const onSubmit = (data: any) => forgotPassword(data);
  useEffect(() => {
    if (isSuccess && data) {
      navigate(Redirect.OTP, { state: { email } });
    }
  }, [data, email, isSuccess, navigate]);
  return {
    onSubmit,
  };
}
