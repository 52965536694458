import CenterProps from "./Center.types";
import "./Center.styles.scss";

export default function Center({ children }: CenterProps) {
  return (
    <div className="center" data-testid="center">
      {children}
    </div>
  );
}
