import { useState } from "react";
import { useToggle } from "src/hooks";
import { Icon } from "src/ui/components";
import { faClose, faGripLines } from "@fortawesome/free-solid-svg-icons";
import Rng from "../Rng";
import "./ThemeSwitch.styles.scss";

export default function ThemeSwitch({
  handleSetShowTheme,
}: {
  handleSetShowTheme: (show: boolean) => void;
}) {
  const { toggle, handleSetToggle } = useToggle(true);
  const [isRunning, setIsRunning] = useState(true);
  const className = toggle ? "themeSwitchWrapper open" : "themeSwitchWrapper";

  const handleSetIsRunning = (_isRunning: boolean) => {
    setIsRunning(_isRunning);
  };

  const handleOpenThemeSwitcher = () => {
    handleSetIsRunning(true);
    handleSetToggle(!toggle);
  };

  const handleCloseThemeSwitcher = () => {
    handleSetIsRunning(false);
    handleSetToggle(false);
  };
  return (
    <div className={className}>
      <div className="themeSwitch">
        <div className="menu">
          <div className="themeSwitchTitle">Pick Ur Meme</div>
          <Rng
            handleSetShowTheme={handleSetShowTheme}
            isRunning={isRunning}
            handleSetIsRunning={handleSetIsRunning}
            toggle={toggle}
          />
        </div>
      </div>
      {toggle ? (
        <div
          className="themeSwitchHandle"
          onClick={() => handleCloseThemeSwitcher()}
        >
          <Icon classNames="themeSwitchIcon" icon={faClose} size="3x" />
        </div>
      ) : (
        <div
          className="themeSwitchHandle"
          onClick={() => handleOpenThemeSwitcher()}
        >
          <Icon classNames="themeSwitchIcon" icon={faGripLines} size="3x" />
        </div>
      )}
    </div>
  );
}
