import { SiteMenuCloseIcon } from "src/ui/features/Icons";
import { Backdrop } from "src/ui/components";
import { Menus } from "src/ui/features";
import SplashMenuItem from "./components";
import { SPLASH_MENU_LINKS } from "./Splash.constants";
import "./Splash.styles.scss";
import ScrollToAnchor from "src/ui/components/ScrollToAnchor";

function SplashHeaderMenu({
  handleToggle,
}: {
  handleToggle: (toggle?: boolean) => void;
}) {
  return (
    <div className="splashMenuHeader" data-testid="splashHeaderMenu">
      <SiteMenuCloseIcon callback={handleToggle} />
    </div>
  );
}

function SplashMenuFooter() {
  return (
    <div className="splashMenuFooter" data-testid="splashHeaderMenu">
      <Menus.Social />
    </div>
  );
}

export default function SplashMenu({
  handleToggle,
  toggle,
}: {
  handleToggle: (toggle?: boolean) => void;
  toggle: boolean;
}) {
  const className = toggle ? "splashMenu show" : "splashMenu";
  return (
    <>
      <div className={className} data-testid="splashMenu">
        <SplashHeaderMenu handleToggle={() => handleToggle(false)} />
        <ScrollToAnchor callback={() => handleToggle()} />
        <div className="splashMenuBody">
          {SPLASH_MENU_LINKS.map(({ name, roles, to }, index) => (
            <SplashMenuItem key={index} name={name} roles={roles} to={to} />
          ))}
        </div>
        <SplashMenuFooter />
      </div>
      <Backdrop show={toggle} handleClick={() => handleToggle(false)} />
    </>
  );
}
