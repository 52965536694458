import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setSplashTheme } from "src/redux/features/ui.slice";
import { useRng } from "src/hooks";
import "./Rng.styles.scss";

export default function Rng({
  handleSetShowTheme,
  isRunning,
  handleSetIsRunning,
  toggle,
}: {
  handleSetShowTheme: (show: boolean) => void;
  isRunning: boolean;
  handleSetIsRunning: (isRunning: boolean) => void;
  toggle: boolean;
}) {
  const { svgs } = useRng();
  const dispatch = useDispatch();
  const [randomNumber, setRandomNumber] = useState(13);
  const [randomStopTime, setRandomStopTime] = useState(0);
  const [shouldStopRandomly, setShouldStopRandomly] = useState(false);
  const [countdown, setCountdown] = useState(0);

  useEffect(() => {
    if (!toggle) {
      setRandomNumber(randomNumber);
    }
  }, [setRandomNumber, randomNumber, toggle]);
  useEffect(() => {
    if (!isRunning && toggle) {
      dispatch(setSplashTheme(svgs[randomNumber].theme));
      handleSetShowTheme(true);
    }
  }, [dispatch, handleSetShowTheme, isRunning, randomNumber, svgs, toggle]);

  useEffect(() => {
    let intervalId: any;
    if (isRunning) {
      intervalId = setInterval(() => {
        const rando = Math.floor(Math.random() * 22);
        setRandomNumber(rando);
      }, 100);
    }
    return () => clearInterval(intervalId);
  }, [isRunning]);

  useEffect(() => {
    let intervalId2: any;
    intervalId2 = setInterval(() => {
      if (shouldStopRandomly) {
        handleSetIsRunning(false);
        setShouldStopRandomly(false);
      }
    }, 1000 * randomStopTime);

    return () => clearInterval(intervalId2);
  }, [handleSetIsRunning, randomStopTime, shouldStopRandomly]);

  useEffect(() => {
    if (!countdown) return;
    const intervalId = setInterval(() => {
      setCountdown(countdown - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [countdown]);

  const stopGenerating = () => {
    const rando = Math.floor(Math.random() * 10) + 1;
    setShouldStopRandomly(true);
    setRandomStopTime(rando);
    setCountdown(rando);
  };

  const startGenerating = () => {
    handleSetShowTheme(false);
    handleSetIsRunning(true);
  };

  return (
    <div className="rng">
      {countdown !== 0 && (
        <div className="changeMessage">{`CHANGE IN ${countdown} SECONDS`}</div>
      )}
      <div className="duckboiRng" id="rng">
        {svgs[randomNumber].svg}
      </div>
      <div>
        {countdown !== 0 ? (
          <button className="countdown">{`${countdown}`}</button>
        ) : isRunning ? (
          <button className="blink" onClick={stopGenerating}>
            Press Stop
          </button>
        ) : (
          <button onClick={startGenerating}>Try again</button>
        )}
      </div>
    </div>
  );
}
