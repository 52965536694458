import moment from "moment";

export function getCopyrightYear() {
  const currentYear = new Date().getFullYear();
  return `© ${currentYear}`;
}

export function getMessageTime(epoch: number) {
  return moment.unix(epoch).calendar();
}

export function getNowToUnixTimestamp() {
  return Math.floor(Date.now() / 1000);
}
