import { useState } from "react";
import { ReactComponent as AxeMurderer } from "src/assets/memes/duckbois/axeMurderer.svg";
import { ReactComponent as Bullish } from "src/assets/memes/duckbois/bullish.svg";
import { ReactComponent as Droog } from "src/assets/memes/duckbois/droog.svg";
import { ReactComponent as FalseIdol } from "src/assets/memes/duckbois/falseidol.svg";
import { ReactComponent as FastFoodJunky } from "src/assets/memes/duckbois/fastfoodJunky.svg";
import { ReactComponent as GameBoy } from "src/assets/memes/duckbois/gameboi.svg";
import { ReactComponent as Grunge } from "src/assets/memes/duckbois/grunge.svg";
import { ReactComponent as Hoodlum } from "src/assets/memes/duckbois/hoodlum.svg";
import { ReactComponent as Jerkoff } from "src/assets/memes/duckbois/jerkoff.svg";
import { ReactComponent as Looney } from "src/assets/memes/duckbois/loon.svg";
import { ReactComponent as MetalHead } from "src/assets/memes/duckbois/metalhead.svg";
import { ReactComponent as MovieBuff } from "src/assets/memes/duckbois/moviebuff.svg";
import { ReactComponent as Og } from "src/assets/memes/duckbois/og.svg";
import { ReactComponent as OgRetarded } from "src/assets/memes/duckbois/ogRetarded.svg";
import { ReactComponent as Popcat } from "src/assets/memes/duckbois/popcat.svg";
import { ReactComponent as Poppedcat } from "src/assets/memes/duckbois/poppedcat.svg";
import { ReactComponent as Punkass } from "src/assets/memes/duckbois/punkass.svg";
import { ReactComponent as Redneck } from "src/assets/memes/duckbois/redneck.svg";
import { ReactComponent as SkateRat } from "src/assets/memes/duckbois/skaterat.svg";
import { ReactComponent as Slasher } from "src/assets/memes/duckbois/dreamSlasher.svg";
import { ReactComponent as Brat } from "src/assets/memes/duckbois/brat.svg";
import { ReactComponent as SweetTooth } from "src/assets/memes/duckbois/sweettooth.svg";
import { ReactComponent as Spoiled } from "src/assets/memes/duckbois/spoiled.svg";
import { ReactComponent as Trash } from "src/assets/memes/duckbois/trash.svg";
import { SplashTheme } from "src/types/IUi.types";
import "./Footer.styles.scss";

export default function Duckboi({
  splashTheme,
}: {
  classNames?: string;
  splashTheme: string;
}) {
  const [pop, setPop] = useState(false);
  return (
    <div className="smallDuckboi">
      {splashTheme === SplashTheme.AXE_MURDERER && <AxeMurderer />}
      {splashTheme === SplashTheme.BRAT && <Brat />}
      {splashTheme === SplashTheme.BULLISH && <Bullish />}
      {splashTheme === SplashTheme.DROOG && <Droog />}
      {splashTheme === SplashTheme.FALSE_IDOL && <FalseIdol />}
      {splashTheme === SplashTheme.FASTFOODJUNKY && <FastFoodJunky />}
      {splashTheme === SplashTheme.GAME_BOY && <GameBoy />}
      {splashTheme === SplashTheme.GRUNGE && <Grunge />}
      {splashTheme === SplashTheme.HOODLUM && <Hoodlum />}
      {splashTheme === SplashTheme.JERKOFF && <Jerkoff />}
      {splashTheme === SplashTheme.LOONEY && <Looney />}
      {splashTheme === SplashTheme.METALHEAD && <MetalHead />}
      {splashTheme === SplashTheme.MOVIE_BUFF && <MovieBuff />}
      {splashTheme === SplashTheme.OG &&
        (pop ? (
          <OgRetarded onClick={() => setPop(false)} />
        ) : (
          <Og onClick={() => setPop(true)} />
        ))}
      {splashTheme === SplashTheme.POPCAT &&
        (pop ? (
          <Poppedcat onClick={() => setPop(false)} />
        ) : (
          <Popcat onClick={() => setPop(true)} />
        ))}
      {splashTheme === SplashTheme.PUNKASS && <Punkass />}
      {splashTheme === SplashTheme.REDNECK && <Redneck />}
      {splashTheme === SplashTheme.SKATERAT && <SkateRat />}
      {splashTheme === SplashTheme.SLASHER && <Slasher />}
      {splashTheme === SplashTheme.SWEETTOOTH && <SweetTooth />}
      {splashTheme === SplashTheme.SPOILED && <Spoiled />}
      {splashTheme === SplashTheme.TRASH && <Trash />}
    </div>
  );
}
