import CountdownProps from "./Countdown.types";
import "./Countdown.styles.scss";
import { useLaunchCountdown } from "src/hooks";

export default function Countdown({ launchDate }: CountdownProps) {
  const [days, hours, minutes, seconds] = useLaunchCountdown(launchDate);

  return (
    <div className="countdown" data-testid="countdown">
      <div>{`${days}d`}</div>
      <div>{`${hours}h`}</div>
      <div>{`${minutes}m`}</div>
      <div>{`${seconds}s`}</div>
    </div>
  );
}
