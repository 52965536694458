import { InferType, object, string } from "yup";

export const defaultValues = {
  email: "",
  name: "",
  message: "",
};

export const validationSchema = object().shape({
  email: string().required().email("Must be a valid email"),
  name: string().required().min(8),
  message: string().required().min(50).max(150),
});

export type ContactPayloadType = InferType<typeof validationSchema>;
