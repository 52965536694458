import {
  faCircleUser,
  faPen,
  faShield,
  faUsers,
  faLock,
  faComments,
} from "@fortawesome/free-solid-svg-icons";
import { UserRoles } from "src/ui/features/User/User.types";

const PROFILE_MENU = [
  {
    icon: faCircleUser,
    text: "Profile",
    roles: UserRoles.USER,
    to: "/profile",
  },
  {
    icon: faPen,
    text: "Edit Profile",
    roles: UserRoles.USER,
    to: "/profile/edit",
  },
  {
    icon: faLock,
    text: "Change Password",
    roles: UserRoles.USER,
    to: "/profile/password/edit",
  },
  {
    icon: faComments,
    text: "Chat",
    roles: UserRoles.USER,
    to: "/chat",
  },
];

const ADMIN_MENU = [
  {
    icon: faShield,
    text: "Edit Role",
    to: "/admin/roles/edit",
    roles: UserRoles.ADMIN,
  },
];

const GOD_MENU = [
  {
    icon: faUsers,
    text: "Users",
    roles: UserRoles.GOD,
    to: "/users",
  },
];

export const SIDE_MENUS = [
  {
    items: PROFILE_MENU,
    role: UserRoles.USER,
    title: "Profile",
  },
  {
    items: ADMIN_MENU,
    role: UserRoles.ADMIN,
    title: "Admin",
  },
  {
    items: GOD_MENU,
    role: UserRoles.GOD,
    title: "God",
  },
];
