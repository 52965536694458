import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { loadMessage } from "src/redux/features/chat.slice";
import { useCookies } from "react-cookie";
import { useAppSelector } from "src/redux/store";

export default function useWs() {
  const [socketUrl] = useState("ws://localhost:8001");
  const dispatch = useDispatch();
  const profile = useAppSelector((state) => state.profileState.profile);
  const { userId } = useParams();
  const [cookies] = useCookies(["user_id"]);
  const { user_id } = cookies;
  const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(
    socketUrl,
    {
      queryParams: {
        user1: user_id,
        user2: userId ? userId?.toString() : "",
      },
    }
  );

  function handleSendSocketMessage(message: string, createdAt: number) {
    sendJsonMessage({
      message,
      createdAt,
      room: [user_id, userId],
      senderName: profile!.name,
    });
  }

  useEffect(() => {
    if (lastJsonMessage) {
      dispatch(loadMessage(lastJsonMessage as any));
    }
  }, [dispatch, lastJsonMessage]);

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  return {
    handleSendSocketMessage,
    connectionStatus,
  };
}
