import { Forms } from "src/ui/features";
import { Layouts } from "src/ui/pages/components";
import "./Login.styles.scss";

export default function LoginPage() {
  return (
    <Layouts.Simple>
      <video autoPlay muted loop id="degenCult1">
        <source src="/assets/degenCult/degenCult1.mp4" type="video/mp4" />
      </video>
      <Forms.LoginForm />
    </Layouts.Simple>
  );
}
