import { useRegisterUserMutation } from "src/redux/api/auth.api";
import { Redirect, usePreloader, useRedirect } from "src/hooks";

export default function useRegister() {
  const [registerUser, { isLoading, isSuccess }] = useRegisterUserMutation();
  const isPreloaderSuccess = isLoading && !isSuccess;
  const isRedirectSuccess = !isLoading && isSuccess;
  const onSubmit = (data: any) => registerUser(data);

  usePreloader(isPreloaderSuccess);
  useRedirect(isRedirectSuccess, Redirect.LOGIN);
  return {
    onSubmit,
  };
}
