import LazyImageProps from "./LazyImage.types";
import { useLazyImage } from "src/hooks";
import { Spinner } from "src/ui/components";

export default function LazyImage({
  alt,
  classNames,
  ref,
  src,
}: LazyImageProps) {
  const { imgSrc } = useLazyImage(src);

  return imgSrc === "" ? (
    <Spinner />
  ) : (
    <img alt={alt} className={classNames} src={src} loading="lazy" ref={ref} />
  );
}
