export enum SplashTheme {
  AXE_MURDERER = "axeMurderer",
  BRAT = "brat",
  BULLISH = "bullish",
  DROOG = "droog",
  FALSE_IDOL = "falseidol",
  FASTFOODJUNKY = "fastfoodJunky",
  GAME_BOY = "gameboy",
  GRUNGE = "grunge",
  HOODLUM = "hoodlum",
  JERKOFF = "jerkoff",
  LOONEY = "looney",
  METALHEAD = "metalhead",
  MOVIE_BUFF = "moviebuff",
  OG = "og",
  POPCAT = "popcat",
  PUNKASS = "punkass",
  REDNECK = "redneck",
  SKATERAT = "skaterat",
  SLASHER = "slasher",
  SWEETTOOTH = "sweettooth",
  SPOILED = "spoiled",
  TRASH = "trash",
}

export enum FriendlyText {
  BLOCKBUSTER = "Blockbuster Video",
  BURGER_KING = "Burger King",
  DUCKBOI_OG = "Og Duckboi",
  GARBAGE_PAIL_KIDS = "Garbage Pail Kids",
  KRISPY_KREME = "Krispy Kreme",
  LOONEY_TUNES = "Looney Tunes",
  METALLICA = "Metallica",
  MISFITS = "Misfits",
  PETERBILT = "Peterbilt",
  POPCAT = "Popcat",
  NIGHTMARE_ON_ELM_ST = "A Nightmare On Elm St",
  NIRVANA_NEVERMIND = "Nirvana Nevermind",
  PORNHUB = "Pornhub",
  SIMS_STAAB = "Sims Staab",
  SUPER_MARIO_BROS_3 = "Super Mario Bros 3",
}
