import { Navigate, Outlet, useLocation } from "react-router-dom";
import { usePreloader, useAuth } from "src/hooks";

export default function ProtectedRoute({ roles }: { roles: string[] }) {
  const location = useLocation();
  const { isLoggedIn, isLoading, hasPermission } = useAuth(roles);
  usePreloader(isLoading);
  if (isLoggedIn && hasPermission()) {
    return <Outlet />;
  } else if (isLoggedIn && !hasPermission()) {
    return <Navigate to="/unauthorized" />;
  } else {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
}
