import BottomProps from "./Bottom.types";
import "./Bottom.styles.scss";

export default function Bottom({ children }: BottomProps) {
  return (
    <div className="bottom" data-testid="bottom">
      {children}
    </div>
  );
}
