import DegenProps from "./Degen.types";
import { Logo } from "src/ui/components";
import { ReactComponent as DegenIcon } from "src/assets/degenDungeonCult/degenHead.svg";

export default function Degen({ classNames, logoText }: DegenProps) {
  return (
    <Logo classNames={classNames} logoText={logoText}>
      <DegenIcon className="degenIcon" />
    </Logo>
  );
}
