import UserContextDropdownProps from "./UserContext.types";
import "./UserContext.styles.scss";
import ContextMenu from "src/ui/components/ContextMenu";
import ContextMenuItem from "src/ui/components/ContextMenuItem";
import { Icon } from "src/ui/components";
import {
  faClose,
  faEdit,
  faEye,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

export default function UserContextDropdown({
  id,
  handleCloseContextMenu,
}: UserContextDropdownProps) {
  return (
    <ContextMenu classNames="userContext">
      <ContextMenuItem text="View" to={`/user/${id}`}>
        <Icon icon={faEye} size="sm" />
      </ContextMenuItem>
      <ContextMenuItem text="Edit" to={`/users/${id}`}>
        <Icon icon={faEdit} size="sm" />
      </ContextMenuItem>
      <ContextMenuItem text="Delete">
        <Icon icon={faTrash} size="sm" />
      </ContextMenuItem>
      <ContextMenuItem text="Close Menu" onClick={handleCloseContextMenu}>
        <Icon icon={faClose} size="sm" />
      </ContextMenuItem>
    </ContextMenu>
  );
}
