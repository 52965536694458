import { useLogoutUserMutation } from "src/redux/api/auth.api";
import { Redirect, usePreloader, useRedirect } from "src/hooks";

export default function useLogout() {
  const [logoutUser, { isLoading, isSuccess }] = useLogoutUserMutation();
  const isPreloaderSuccess = isLoading && !isSuccess;
  const isRedirectSuccess = !isLoading && isSuccess;

  usePreloader(isPreloaderSuccess);
  useRedirect(isRedirectSuccess, Redirect.LOGIN);

  return {
    logoutUser,
  };
}
