import ChatInputProps from "./ChatInput.types";
import "./ChatInput.styles.scss";

export default function ChatInput({
  handleOnChange,
  handleOnKeyDown,
  message,
}: ChatInputProps) {
  return (
    <textarea
      onChange={handleOnChange}
      onKeyDown={handleOnKeyDown}
      className="input chat"
      name="chat"
      value={message}
      placeholder="Message..."
    />
  );
}
