import { Forms } from "src/ui/features";
import { Layouts } from "../components";

export default function LoginPage() {
  return (
    <Layouts.Simple>
      <Forms.RegisterForm />
    </Layouts.Simple>
  );
}
