import { faAnglesLeft, faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import { Icon, Tooltip } from "src/ui/components";
import IconsProps from "src/ui/features/Icons/Icons.types";
import "./Left.styles.scss";

export default function LeftSidebarToggleIcon({
  callback,
  toggle,
}: IconsProps) {
  const leftToggle = toggle ? faAnglesLeft : faAnglesRight;

  return (
    <div className="toggle">
      <Tooltip message={toggle ? `close` : `open`}>
        <Icon icon={leftToggle} handleClick={callback} />
      </Tooltip>
    </div>
  );
}
