import { Outlet } from "react-router-dom";
import { Layout, PageTitle, Page } from "src/ui/components";
import { Layouts } from "src/ui/features";
import { Menus } from "src/ui/features";

export default function ProfilePageLayout({
  pageTitle,
}: {
  pageTitle?: string;
}) {
  return (
    <Layout>
      <Layouts.Header />
      <Layout.Middle>
        <Layouts.LeftSidebar>
          <Menus.Profile />
        </Layouts.LeftSidebar>
        <Layout.Center>
          <Page>
            <Page.Top>
              <PageTitle pageTitle={pageTitle} />
            </Page.Top>
            <Page.Main>
              <Outlet />
            </Page.Main>
            <Page.Bottom></Page.Bottom>
          </Page>
        </Layout.Center>
      </Layout.Middle>
    </Layout>
  );
}
