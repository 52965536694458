import ProfileProps from "./Profile.types";
import { useGetUserQuery } from "src/redux/api/user.api";
import {
  PageTitle as Username,
  UserDetails,
  UserPfp,
  UserRoles,
} from "src/ui/components";

export default function Profile({ userId }: ProfileProps) {
  const { data: profile } = useGetUserQuery(userId!);
  return profile ? (
    <div className="profile" data-testid="profile">
      <Username pageTitle={profile.name} />
      <UserPfp />
      <UserRoles roles={profile.roles!} />
      <UserDetails email={profile.email} name={profile.name} />
    </div>
  ) : null;
}
