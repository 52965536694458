import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "./customFetchBase";
import { IUser } from "src/types/IUser.types";
import { LoginPayloadType } from "src/ui/features/Forms/Login/Login.schema";
import { RegisterPayloadType } from "src/ui/features/Forms/Register/Register.schema";
import { ChangePasswordPayloadType } from "src/ui/features/Forms/ChangePassword/ChangePassword.schema";
import { ForgotPasswordPayloadType } from "src/ui/features/Forms/ForgotPassword/ForgotPassword.schema";
import { logout, setProfile } from "src/redux/features/profile.slice";
import { OtpLoginPayloadType } from "src/ui/pages/Login/OneTimePasscode/OneTimePasscode.schema";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: customFetchBase,
  tagTypes: ["Profile"],
  endpoints: (builder) => ({
    registerUser: builder.mutation<IUser, RegisterPayloadType>({
      query(data) {
        return {
          url: "auth/register",
          method: "POST",
          body: data,
        };
      },
    }),
    loginUser: builder.mutation<
      { access_token: string; status: string; user: IUser },
      LoginPayloadType
    >({
      query(data) {
        return {
          url: "auth/login",
          method: "POST",
          body: data,
          credentials: "include",
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setProfile(data.user));
        } catch (error) {}
      },
    }),
    changePassword: builder.mutation<
      { access_token: string; status: string },
      ChangePasswordPayloadType
    >({
      query(data) {
        return {
          credentials: "include",
          url: "auth/changePassword",
          method: "POST",
          body: data,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          await dispatch(authApi.endpoints.logoutUser.initiate());
        } catch (error) {}
      },
    }),
    logoutUser: builder.mutation<void, void>({
      query() {
        return {
          url: "auth/logout",
          credentials: "include",
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          await dispatch(authApi.endpoints.logoutUser.initiate());
          await dispatch(logout());
        } catch (error) {}
      },
    }),
    forgotPassword: builder.mutation<any, ForgotPasswordPayloadType>({
      query(data) {
        return {
          credentials: "include",
          url: "comms/otp",
          method: "POST",
          body: data,
        };
      },
    }),
    otpLogin: builder.mutation<
      { access_token: string; status: string; user: IUser },
      OtpLoginPayloadType
    >({
      query(data) {
        return {
          url: "auth/otp",
          method: "POST",
          body: data,
          credentials: "include",
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setProfile(data.user));
        } catch (error) {}
      },
    }),
  }),
});

export const {
  useChangePasswordMutation,
  useLoginUserMutation,
  useRegisterUserMutation,
  useLogoutUserMutation,
  useForgotPasswordMutation,
  useOtpLoginMutation,
} = authApi;
