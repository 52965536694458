import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDebounce, useUserSearch } from "src/hooks";
import UserSearchbarProps from "./User.types";
import { Searchbar, SearchResults } from "src/ui/components";
import "./User.styles.scss";

export default function UserSearchbar({ classNames }: UserSearchbarProps) {
  const [searchTerm, setSearchTerm] = useState("");
  const location = useLocation();
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  function handleOnChange(e: any) {
    setSearchTerm(e.target.value);
  }
  useEffect(() => {
    setSearchTerm("");
  }, [location]);
  return (
    <div className="userSearchBar">
      <Searchbar
        classNames={classNames}
        onChange={handleOnChange}
        placeholder="Search Users"
        value={searchTerm}
      />
      {debouncedSearchTerm.length > 2 && (
        <SearchResults
          callback={useUserSearch}
          linkPath="/chat/"
          searchTerm={debouncedSearchTerm}
        />
      )}
    </div>
  );
}
