import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useAddMessageMutation } from "src/redux/api/chat.api";
import { useWs } from "src/hooks";
import { getNowToUnixTimestamp } from "src/utils/date.utils";

export default function useChat() {
  const { handleSendSocketMessage } = useWs();
  const [message, setMessage] = useState("");
  const { userId } = useParams();
  const [addMessage] = useAddMessageMutation();
  const user = useSelector((state: any) => {
    return state!.userState!.user;
  });
  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSendMessage();
      e.currentTarget.style = "height: 47px";
    }
  };

  function handleSendMessage() {
    if (message.trim() === "") return;
    addMessage({ message, receiver: userId, receiverName: user!.name });
    handleSendSocketMessage(message, getNowToUnixTimestamp());
    setMessage("");
  }

  function handleOnChange(e: any) {
    setMessage(e.target.value);
  }

  return {
    handleSendMessage,
    handleOnChange,
    message,
    handleKeyDown,
  };
}
