import { IconMenu, SIDE_MENUS } from "src/ui/features/Menus";
import "./Profile.styles.scss";

export default function Profile() {
  return (
    <div className="profile" data-testid="profile">
      {SIDE_MENUS.map(({ items, role, title }, index) => (
        <IconMenu key={index} menuItems={items} role={role} title={title} />
      ))}
    </div>
  );
}
