import { Chat } from "src/ui/components";
import { PROMPTS } from "./Prompts.constants";
import "./Prompts.styles.scss";

export default function Prompts() {
  return (
    <div className="prompts">
      {PROMPTS.map((column, i) => (
        <div key={i}>
          {column.map((prompt, ii) => (
            <Chat.Prompt key={`${i}-${ii}`} prompt={prompt} />
          ))}
        </div>
      ))}
    </div>
  );
}
