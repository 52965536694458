import { ReactComponent as Shitshow } from "src/assets/splash/shitshow.svg";
import "./Section8.styles.scss";

export default function Section8() {
  return (
    <div id="section8" className="section8">
      <Shitshow />
    </div>
  );
}
