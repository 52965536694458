import { Icon } from "src/ui/components";
import { faBars } from "@fortawesome/free-solid-svg-icons";

export default function Header({ openMenu }: { openMenu: () => void }) {
  return (
    <div className="menuWrapper">
      <Icon
        classNames="splashMenuIcon"
        icon={faBars}
        handleClick={openMenu}
        size="4x"
      />
    </div>
  );
}
