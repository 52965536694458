import { InferType, object, string } from "yup";

export const defaultValues = {
  chatRoom: "",
};

export const validationSchema = object().shape({
  chatRoom: string().required().min(8),
});

export type AddChatRoomPayloadType = InferType<typeof validationSchema>;
