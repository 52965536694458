import ChatMessageProps from "./Message.types";
import { Chat } from "src/ui/components";
import "./Message.styles.scss";

export default function Message({
  createdAt,
  message,
  senderName,
  user,
}: ChatMessageProps) {
  const className =
    user && user!.name === senderName
      ? "chatMessage receiver"
      : "chatMessage sender";
  return (
    <div className={className}>
      <Chat.Timestamp createdAt={createdAt} />
      <span>{message}</span>
    </div>
  );
}
