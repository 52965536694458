import { Layout, Page } from "src/ui/components";
import { Layouts } from "src/ui/features";

export default function DefaultPageLayout({
  children,
}: {
  children?: JSX.Element[] | JSX.Element;
}) {
  return (
    <Layout>
      <Layouts.Header />
      <Layout.Middle>
        <Layout.Center>
          <Page>
            <Page.Main>{children}</Page.Main>
          </Page>
        </Layout.Center>
      </Layout.Middle>
      <Layouts.Footer />
    </Layout>
  );
}
