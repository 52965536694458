import { Menus } from "src/ui/features";
import { Spoof } from "../Spoof";
import "./Footer.styles.scss";
import { Link } from "react-router-dom";

export default function Footer({
  handleShowContactModal,
  handleShowTosModal,
  splashTheme,
}: {
  handleShowContactModal: () => void;
  handleShowTosModal: () => void;
  splashTheme: string;
}) {
  return (
    <div className="splashFooter">
      <div>
        <Spoof.Footer classNames="footerDuckboi" splashTheme={splashTheme} />
      </div>
      <div className="middle">
        <div className="footerLinks">
          <div>
            <Link to="/#section2">Whitepaper</Link>
            <Link to="/#section5">Tokenomics</Link>
            <Link to="/#section3">Join The Cult</Link>
          </div>
          <div>
            <div className="contactButton" onClick={handleShowContactModal}>
              Contact
            </div>
            <Link to="/degen_dungeon_cult">Degen Dungeon Cult</Link>
            <div className="contactButton" onClick={handleShowTosModal}>
              ToS
            </div>
          </div>
        </div>
        <Menus.Social classNames="splashSocial" />
      </div>
      <div className="monogram">
        <Spoof.Monogram splashTheme={splashTheme} />
        <div>© 2025 Duckboi</div>
      </div>
    </div>
  );
}
