import { Outlet } from "react-router-dom";
import { Tables } from "src/ui/features";

export default function UsersPage() {
  return (
    <>
      <Outlet />
      <Tables.Users />
    </>
  );
}
