import { useState } from "react";
import { UserPfpProps } from "./UserPfp.types";
import { randomIntFromInterval } from "src/utils";
import { LazyImage } from "src/ui/components";
import "./UserPfp.styles.scss";

export default function UserPfp({ classNames }: UserPfpProps) {
  const [randomInt] = useState(randomIntFromInterval(1, 5));
  const className = `userPfp ${classNames}`;
  return (
    <div className={className} data-testid="userPfp">
      <LazyImage
        alt="randomPfp"
        src={`../../../assets/pfps/rando${randomInt}.svg`}
      />
    </div>
  );
}
