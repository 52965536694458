import MessagesProps from "./Messages.types";
import { Chat } from "src/ui/components";
import "./Messages.styles.scss";

export default function Messages({ messages, profile }: MessagesProps) {
  return profile ? (
    <>
      {/* <Chat.ProfileBanner classNames="small" /> */}
      <div id="messages" className="messages" data-testid="messages">
        {messages.map(({ createdAt, message, senderName }, index) => {
          const classNames = profile!.name === senderName ? "me" : undefined;
          return (
            <Chat.Message
              classNames={classNames}
              createdAt={createdAt}
              key={index}
              message={message}
              name={senderName}
            />
          );
        })}
      </div>
    </>
  ) : null;
}
