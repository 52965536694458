import Input from "./Input";
import List from "./List";
import Message from "./Message";
import Messages from "./Messages";
import Placeholder from "./Placeholder";
import { Window } from "./Window";
import ProfileBanner from "./ProfileBanner";
import Prompt from "./Prompt";
import Prompts from "./Prompts";
import Sender from "./Sender";
import Timestamp from "./Timestamp";
import { PROMPTS, ROLES } from "./Input/Input.types";

export const Chat = {
  Input,
  List,
  Message,
  Messages,
  Placeholder,
  ProfileBanner,
  Prompt,
  Prompts,
  Sender,
  Timestamp,
  Window,
  PROMPTS,
  ROLES,
};
