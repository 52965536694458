import { useEffect, useState } from "react";
import { useSearchUsersQuery } from "src/redux/api/users.api";

export default function useUserSearch(searchTerm: string) {
  const [filteredSearchTerm, setFilteredSearchTerm] = useState(searchTerm);
  const { data, error, isLoading, isFetching } =
    useSearchUsersQuery(filteredSearchTerm);

  useEffect(() => {
    if (searchTerm.length !== 0 || searchTerm.length > 2) {
      setFilteredSearchTerm(searchTerm);
    }
  }, [searchTerm]);

  return {
    data,
    error,
    isLoading,
    isFetching,
  };
}
