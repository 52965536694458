import Ticker from "../../Ticker";
import { ReactComponent as DegenHead } from "src/assets/degenDungeonCult/degenHead.svg";
import "./Three.styles.scss";

export default function Three() {
  return (
    <Ticker classNames="degenHead" reverse={true}>
      <DegenHead className="degenHeadTick" />
      <DegenHead className="degenHeadTick" />
      <DegenHead className="degenHeadTick" />
      <DegenHead className="degenHeadTick" />
      <DegenHead className="degenHeadTick" />
      <DegenHead className="degenHeadTick" />
      <DegenHead className="degenHeadTick" />
    </Ticker>
  );
}
