import { PageTitle } from "src/ui/components";
import { Layouts } from "../components";

export default function UnauthorizedPage() {
  return (
    <Layouts.DefaultPage>
      <PageTitle pageTitle="401" />
      <img
        alt="Ludwig says nope"
        width="400"
        src="/assets/animations/ludwig.gif"
      />
    </Layouts.DefaultPage>
  );
}
