import { InferType, object, string } from "yup";

export const defaultValues = {
  email: "",
  name: "",
  walletAddress: "",
};

export const validationSchema = object().shape({
  email: string().required().email("Must be a valid email"),
  name: string().required().min(8),
  walletAddress: string().required().min(42).max(42),
});

export type JoinNowPayloadType = InferType<typeof validationSchema>;
