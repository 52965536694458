import { useEffect, useRef } from "react";
import MessageProps from "./Message.types";
import { Chat, Ellipse } from "src/ui/components";
import "./Message.styles.scss";

export default function Message({
  classNames,
  createdAt,
  message,
  name,
}: MessageProps) {
  const messageRef = useRef<HTMLInputElement>(null);
  const className = classNames ? `message ${classNames}` : "message";
  useEffect(() => {
    if (messageRef.current) {
      document!.getElementById("messages")!.scrollTo({
        top: messageRef.current.offsetTop,
        left: 0,
      });
    }
  }, []);
  return (
    <div className={className} ref={messageRef}>
      <div />
      <Ellipse length={15} text={name} />
      <div>
        {message}
        <Chat.Timestamp createdAt={createdAt} />
      </div>
    </div>
  );
}
