import { useLocation } from "react-router-dom";
import { useOtpLoginMutation } from "src/redux/api/auth.api";
import { Redirect, useRedirect } from "src/hooks";

export default function useOtpLogin() {
  const location = useLocation();
  const [loginOtpUser, { isSuccess }] = useOtpLoginMutation();
  const onSubmit = (otp: number) =>
    loginOtpUser({ otp: otp.toString(), email: location?.state?.email });
  useRedirect(isSuccess, Redirect.PROFILE);
  return {
    onSubmit,
  };
}
