import { useState } from "react";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { Badge, Icon } from "src/ui/components";
import { Ellipse, Tooltip } from "src/ui/components";
import { setTooltipDirection } from "src/ui/components/Table/Table.utils";
import { Dropdowns } from "src/ui/features";
import "./Body.styles.scss";

export default function TableRow({ ...props }: any) {
  const { data, maxCellTextLength } = props;
  const [id, setId] = useState("");

  return (
    <tbody key="tbody">
      {data?.map((datum: any, i: number) => {
        return (
          <tr key={i}>
            <td>
              <Icon
                classNames="userContextEllipses"
                icon={faEllipsisVertical}
                handleClick={() => {
                  if (datum._id === id) {
                    setId("");
                  } else {
                    setId(datum._id);
                  }
                }}
                size="lg"
              />
              {id === datum._id && (
                <Dropdowns.UserContextDropdown
                  id={datum._id}
                  handleCloseContextMenu={() => setId("")}
                />
              )}
            </td>
            {Object.values(datum).map((value: any, iii, array) => {
              if (value.constructor.name === "Array") {
                return (
                  <td key={`${value}-${iii}`}>
                    <div className="badges">
                      {value.map((val: string) => (
                        <Badge key={val} classNames="tiny" text={val[0]} />
                      ))}
                    </div>
                  </td>
                );
              } else {
                return value &&
                  maxCellTextLength &&
                  value.length > maxCellTextLength ? (
                  <td key={`${value}-${iii}`}>
                    <Tooltip
                      message={value}
                      delay={0}
                      direction={setTooltipDirection(iii, array.length)}
                    >
                      <Ellipse length={maxCellTextLength} text={value} />
                    </Tooltip>
                  </td>
                ) : value !== null ? (
                  <td key={`${value}-${iii}`}>{value}</td>
                ) : (
                  <td className="nullValue" key={`${value}-${iii}`}>
                    <i>null</i>
                  </td>
                );
              }
            })}
          </tr>
        );
      })}
    </tbody>
  );
}
