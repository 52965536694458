import { Wrapper } from "src/ui/components";
import { Backdrop } from "src/ui/components";
import { Spinner } from "src/ui/components";

export default function PreLoader() {
  return (
    <Backdrop show={true}>
      <Wrapper classNames="preloader">
        <Spinner />
      </Wrapper>
    </Backdrop>
  );
}
