import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "./customFetchBase";
import { IUser } from "src/types/IUser.types";
import { setUser } from "../features/user.slice";
export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: customFetchBase,
  tagTypes: ["Users"],
  endpoints: (builder) => ({
    getUser: builder.query<IUser, string>({
      query(userId) {
        return {
          credentials: "include",
          url: `user/${userId}`,
          method: "GET",
        };
      },
      transformResponse: (result: { data: { user: IUser } }) =>
        result.data.user,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setUser(data));
        } catch (error) {}
      },
    }),
  }),
});

export const { useGetUserQuery, useLazyGetUserQuery } = userApi;
