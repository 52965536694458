import { profileApi } from "src/redux/api/profile.api";
import { useCookies } from "react-cookie";

export default function useAuth(roles: string[]) {
  const [cookies] = useCookies(["logged_in", "user_roles"]);
  const { logged_in, user_roles } = cookies;
  const { isFetching, isLoading: loading } =
    profileApi.endpoints.getMe.useQuery(undefined, {
      skip: false,
      refetchOnMountOrArgChange: true,
    });
  const isLoading = isFetching || loading;
  const isLoggedIn = Boolean(logged_in);

  function hasPermission() {
    return (
      user_roles &&
      user_roles.find((role: string) => {
        return roles.includes(role);
      })
    );
  }

  hasPermission();

  return {
    isLoading,
    isLoggedIn,
    hasPermission,
  };
}
