import { useChat } from "src/hooks";
import { SendChatIcon } from "src/ui/features/Icons";
import ChatInput from "../ChatInput";
import "./Footer.styles.scss";

export default function ChatWindowFooter() {
  const { handleKeyDown, handleOnChange, handleSendMessage, message } =
    useChat();

  return (
    <div className="chatFooter">
      <div className="chatFooterLeft">
        <ChatInput
          handleOnChange={handleOnChange}
          handleOnKeyDown={handleKeyDown}
          message={message}
        />
      </div>
      <div className="chatFooterRight">
        <SendChatIcon callback={handleSendMessage} />
      </div>
    </div>
  );
}
