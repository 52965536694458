import "./Section2.styles.scss";

export default function Section2() {
  return (
    <div id="section2" className="section2">
      <div className="duckboiBackstory">
        <div className="backstoryText">
          <h1>In the gutter</h1>
          <p>
            Me and my foul mouth gunna waddle around the crypto space 'n watch
            all you dirty, degenerate apes fling poop at each other, and flex
            your stupid jpegs.
          </p>
          <p>
            I will leave this pitiful planet soon, before it turns into a
            supreme shitshow and the universe rug pulls all you jerkoffs.
          </p>
          <p>
            Buy a fat bag of $DUCKBOI, if you dare, and help me get the fuck
            outta here.
          </p>
          <p>
            {`If you need a cheap shill, here is the whitepaper. Else, dial 1(800) EAT-SHIT.`}
          </p>
          <p>
            <a
              className="downloadWhitePaper"
              href={
                process.env.PUBLIC_URL +
                "/whitepaper/nevermindTheWhitepaper.pdf"
              }
              target="_blank"
              rel="noreferrer"
            >
              View the whitepaper
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
