import { faComments } from "@fortawesome/free-solid-svg-icons";
import { ChatRoomModalProps } from "./ChatRoom.types";
import { Modal } from "src/ui/components";
import { AddChatRoomForm } from "src/ui/features/Forms";
import "./ChatRoom.styles.scss";

export default function ChatRoomModal({
  handleShowModal,
  showModal,
  size,
}: ChatRoomModalProps) {
  return showModal ? (
    <Modal classNames="chatModal" data-testid="chatModal">
      <Modal.Header handleClick={handleShowModal} icon={faComments} size={size}>
        <h3>Add Chat Room</h3>
      </Modal.Header>
      <Modal.Body>
        <AddChatRoomForm handleShowModal={handleShowModal} />
      </Modal.Body>
    </Modal>
  ) : null;
}
