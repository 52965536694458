import RightProps from "./Right.types";
import "./Right.styles.scss";

export default function Right({ children }: RightProps) {
  return (
    <div className="right" data-testid="right">
      {children}
    </div>
  );
}
