import { Social } from "src/ui/components";
import { SOCIAL_MENU } from "./Social.constants";
import "./Social.styles.scss";
import SocialProps from "./Social.types";

export default function SocialMenu({ classNames }: SocialProps) {
  classNames = classNames ? `socialMenu ${classNames}` : "socialMenu";
  return (
    <div className={classNames} data-testid="socialMenu">
      {SOCIAL_MENU.map(({ href, icon }, index) => {
        return <Social href={href} icon={icon} key={index} />;
      })}
    </div>
  );
}
