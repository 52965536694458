import { Link } from "react-router-dom";
import IconMenuItemProps from "./IconMenuItem.types";
import { Icon } from "src/ui/components";
import "./IconMenuItem.styles.scss";

export default function IconMenuItem({ icon, text, to }: IconMenuItemProps) {
  return (
    <div className="iconMenuItem" data-testid="iconMenuItem">
      <Icon icon={icon} />
      <Link to={to}>{text}</Link>
    </div>
  );
}
