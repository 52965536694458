import { usePreloader } from "src/hooks";
import { useMemberJoinMutation } from "src/redux/api/member.api";

export default function useMember() {
  const [memberJoin, { isLoading, isSuccess }] = useMemberJoinMutation();
  const isPreloaderSuccess = isLoading && !isSuccess;
  const onSubmit = (data: any) => memberJoin(data);

  usePreloader(isPreloaderSuccess);
  return {
    onSubmit,
  };
}
