import { useEffect, useState } from "react";

export default function useLazyImage(src: string) {
  const [imgSrc, setImgSrc] = useState("");

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => setImgSrc(src);
  }, [src]);

  return {
    imgSrc,
  };
}
