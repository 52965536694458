import Tokenomics from "../../Tokenomics";
import "./Section5.styles.scss";

export default function Section5() {
  return (
    <div id="section5" className="section5">
      <h1 className="sectionTitle">$DUCKBOI</h1>
      <Tokenomics />
    </div>
  );
}
