import { Link, useParams } from "react-router-dom";
import ProfileBannerProps from "./ProfileBanner.types";
import { useGetUserQuery } from "src/redux/api/user.api";
import { PageTitle as Username, UserPfp } from "src/ui/components";
import "./ProfileBanner.styles.scss";

export default function ProfileBanner({ classNames }: ProfileBannerProps) {
  const { userId } = useParams();
  const { data: profile } = useGetUserQuery(userId!);
  return profile ? (
    <Link
      to={`/user/${profile._id}`}
      className="profileBanner"
      data-testid="profileBanner"
    >
      <Username pageTitle={profile.name} classNames={classNames} />
      <UserPfp classNames={classNames} />
    </Link>
  ) : null;
}
