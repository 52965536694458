import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "./customFetchBase";
import { loadMessages, loadChats, loadChat } from "../features/chat.slice";
import { IChatMessage } from "../types";

export const chatApi = createApi({
  reducerPath: "chatApi",
  baseQuery: customFetchBase,
  tagTypes: ["Chat"],
  endpoints: (builder) => ({
    getChats: builder.query<any, void>({
      query() {
        return {
          credentials: "include",
          url: `chat/chats/list`,
          method: "GET",
        };
      },
      providesTags: ["Chat"],
      transformResponse: ({ data }) => data.chats,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(loadChats(data));
        } catch (error) {
          console.log("error", error);
        }
      },
    }),
    getMessages: builder.query<any, any>({
      query(receiver) {
        return {
          credentials: "include",
          url: `chat/messages/${receiver}`,
          method: "GET",
        };
      },
      providesTags: ["Chat"],
      transformResponse: ({ data }) => data,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(loadMessages(data.messages));
          dispatch(loadChat(data.chat));
        } catch (error) {
          console.log("error", error);
        }
      },
      forceRefetch() {
        return true;
      },
    }),
    addMessage: builder.mutation<IChatMessage, Partial<IChatMessage>>({
      query(data) {
        return {
          credentials: "include",
          url: `chat`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: [{ type: "Chat" }],
    }),
    deleteChat: builder.mutation<{ success: boolean; id: number }, number>({
      query(chatId) {
        return {
          credentials: "include",
          url: `chat/${chatId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: (result: any, error: any, chatId: number) => [
        { type: "Chat", chatId },
      ],
    }),
  }),
});

export const {
  useGetChatsQuery,
  useGetMessagesQuery,
  useLazyGetMessagesQuery,
  useAddMessageMutation,
  useDeleteChatMutation,
} = chatApi;
