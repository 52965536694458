export const MULTI_SELECT_OPTIONS = [
  {
    id: "god",
  },
  {
    id: "admin",
  },
  {
    id: "user",
  },
];
