import Ticker from "../../Ticker";

export default function One() {
  return (
    <Ticker reverse={true}>
      <div className="splashTick">
        <span>H0DL FUKKRS</span>
      </div>
      <div className="splashTick">
        <span>U71L17Y 15 D34D</span>
      </div>
      <div className="splashTick">
        <span>5UP M3M3 WH0R35</span>
      </div>
      <div className="splashTick">
        <span>KW4K D363N 817CH35</span>
      </div>
      <div className="splashTick">
        <span>R34DY 537 RU6</span>
      </div>
      <div className="splashTick">
        <span>D1R7Y 571NKY D363N</span>
      </div>
      <div className="splashTick">
        <span>KW4K KW4K FUD</span>
      </div>
      <div className="splashTick">
        <span>LF6 KW4K LF6</span>
      </div>
      <div className="splashTick">
        <span>F0M0 KW4K K1LLzZZ</span>
      </div>
      <div className="splashTick">
        <span>M3H KW4K KW4K</span>
      </div>
      <div className="splashTick">
        <span>KW4K FUK FUK</span>
      </div>
      <div className="splashTick">
        <span>KW4K KW4K KW4K</span>
      </div>
      <div className="splashTick">
        <span>5H1LL 5H1LL 5H1LL</span>
      </div>
    </Ticker>
  );
}
