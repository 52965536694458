import { useRef } from "react";
import { TEXT_AREA } from "./Input.types";
import { useChat } from "src/hooks";
import { useTextAreaAutoSize } from "src/hooks";
import { SendIcon } from "src/ui/features/Icons";
import "./Input.styles.scss";

export default function ChatInput() {
  const { handleKeyDown, handleOnChange, handleSendMessage, message } =
    useChat();
  const inputRef = useRef<HTMLTextAreaElement>(null);
  useTextAreaAutoSize(inputRef.current, message);

  return (
    <div className="chatInput" data-testid="chatInput">
      <textarea
        className={TEXT_AREA.CLASSNAME}
        id="chatInput"
        onChange={handleOnChange}
        onKeyDown={handleKeyDown}
        placeholder={TEXT_AREA.PLACEHODER}
        ref={inputRef}
        rows={1}
        value={message}
      />
      <SendIcon callback={handleSendMessage} />
    </div>
  );
}
