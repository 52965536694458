import Ticker from "../../Ticker";

export default function Four() {
  return (
    <Ticker classNames="four" reverse={true}>
      <div className="splashTick">
        <span>R3L1610N 15 D34D.</span>
      </div>
      <div className="splashTick">
        <span>L1F3 15 4 DUM8 64M3.</span>
      </div>
      <div className="splashTick">
        <span>U71L17Y 15 5H17.</span>
      </div>
      <div className="splashTick">
        <span>FUCK 83L13F.</span>
      </div>
      <div className="splashTick">
        <span>83 D3LU510N4L.</span>
      </div>
      <div className="splashTick">
        <span>M3M3 WH0R3.</span>
      </div>
    </Ticker>
  );
}
