import { useLocation } from "react-router-dom";
import { useLoginUserMutation } from "src/redux/api/auth.api";
import { Redirect, useRedirect } from "src/hooks";

export default function useLogin() {
  const location = useLocation();
  const [loginUser, { isSuccess }] = useLoginUserMutation();
  const onSubmit = (data: any) => loginUser(data);
  const redirectUrl = location?.state?.from?.pathname ?? Redirect.PROFILE;
  useRedirect(isSuccess, redirectUrl);

  return {
    onSubmit,
  };
}
