import TimestampProps from "./Timestamp.types";
import { getMessageTime } from "src/utils";
import "./Timestamp.styles.scss";

export default function Timestamp({ createdAt }: TimestampProps) {
  return (
    <div className="timestamp">
      <div>{getMessageTime(createdAt)}</div>
    </div>
  );
}
