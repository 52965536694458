import { useContact } from "src/hooks";
import { Form, FormFooter, Input, TextArea, Wrapper } from "src/ui/components";
import { Buttons } from "src/ui/features";
import { defaultValues, validationSchema } from "./Contact.schema";
import "./Contact.styles.scss";
import { useEffect } from "react";

export default function ContactForm({
  closeModal,
}: {
  closeModal: (close: boolean) => void;
}) {
  const { onSubmit, isSuccess } = useContact();
  useEffect(() => {
    if (isSuccess) {
      closeModal(false);
    }
  }, [closeModal, isSuccess]);

  return (
    <Wrapper classNames="contactForm" dataTestId="contactForm">
      <Form
        data-testid="contactForm"
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        validation={validationSchema}
      >
        <Input name="name" placeholder="Name" inputType="text" />
        <Input name="email" placeholder="Email" inputType="email" />
        <TextArea name="message" placeholder="Message" inputType="message" />
        <FormFooter classNames="form">
          <Buttons.Button buttonText="Contact Duckboi" classNames="contact" />
        </FormFooter>
      </Form>
    </Wrapper>
  );
}
