// import { useState } from "react";
import { ReactComponent as AxeMurderer } from "src/assets/memes/logos/axeMurderer.svg";
import { ReactComponent as Bullish } from "src/assets/memes/logos/bullish.svg";
import { ReactComponent as Droog } from "src/assets/memes/logos/droog.svg";
import { ReactComponent as FalseIdol } from "src/assets/memes/logos/falseidol.svg";
import { ReactComponent as FastFood } from "src/assets/memes/logos/fastfood.svg";
import { ReactComponent as GameBoy } from "src/assets/memes/logos/gameboy.svg";
import { ReactComponent as Grunge } from "src/assets/memes/logos/grunge.svg";
import { ReactComponent as Hoodlum } from "src/assets/memes/logos/hoodlum.svg";
import { ReactComponent as Jerkoff } from "src/assets/memes/logos/jerkoff.svg";
import { ReactComponent as Looney } from "src/assets/memes/logos/looney.svg";
import { ReactComponent as MetalHead } from "src/assets/memes/logos/metalhead.svg";
import { ReactComponent as MovieBuff } from "src/assets/memes/logos/moviebuff.svg";
import { ReactComponent as Og } from "src/assets/memes/logos/og.svg";
import { ReactComponent as Popcat } from "src/assets/memes/logos/popcat.svg";
import { ReactComponent as Punkass } from "src/assets/memes/logos/punkass.svg";
import { ReactComponent as Redneck } from "src/assets/memes/logos/redneck.svg";
import { ReactComponent as SkateRat } from "src/assets/memes/logos/skaterat.svg";
import { ReactComponent as Slasher } from "src/assets/memes/logos/slasher.svg";
import { ReactComponent as Brat } from "src/assets/memes/logos/brat.svg";
import { ReactComponent as SweetTooth } from "src/assets/memes/logos/sweettooth.svg";
import { ReactComponent as Spoiled } from "src/assets/memes/logos/spoiled.svg";
import { ReactComponent as Trash } from "src/assets/memes/logos/trash.svg";
import { SplashTheme } from "src/types/IUi.types";
import "./Logo.styles.scss";

export default function Logo({ splashTheme }: { splashTheme: string }) {
  // const [logoSwitch, setLogoSwitch] = useState(0);
  // function handleSetLogoSwitch() {
  //   const rng = Math.floor(Math.random() * 3);
  //   setLogoSwitch(rng);
  // }

  return (
    <div className="ripoffLogo">
      {splashTheme === SplashTheme.AXE_MURDERER && (
        <AxeMurderer className="small" />
      )}
      {splashTheme === SplashTheme.BULLISH && <Bullish />}
      {splashTheme === SplashTheme.DROOG && <Droog />}
      {splashTheme === SplashTheme.FALSE_IDOL && (
        <FalseIdol className="medium" />
      )}
      {splashTheme === SplashTheme.FASTFOODJUNKY && (
        <FastFood className="small" />
      )}
      {splashTheme === SplashTheme.GAME_BOY && <GameBoy />}
      {splashTheme === SplashTheme.GRUNGE && <Grunge />}
      {splashTheme === SplashTheme.HOODLUM && <Hoodlum className="medium" />}
      {splashTheme === SplashTheme.JERKOFF && <Jerkoff className="medium" />}
      {splashTheme === SplashTheme.LOONEY && <Looney />}
      {splashTheme === SplashTheme.METALHEAD && <MetalHead />}
      {splashTheme === SplashTheme.MOVIE_BUFF && (
        <MovieBuff className="medium" />
      )}
      {splashTheme === SplashTheme.OG && <Og />}
      {splashTheme === SplashTheme.POPCAT && <Popcat />}
      {splashTheme === SplashTheme.PUNKASS && <Punkass />}
      {splashTheme === SplashTheme.REDNECK && <Redneck />}
      {splashTheme === SplashTheme.SKATERAT && <SkateRat className="medium" />}
      {splashTheme === SplashTheme.SLASHER && <Slasher />}
      {splashTheme === SplashTheme.BRAT && <Brat className="medium" />}
      {splashTheme === SplashTheme.SWEETTOOTH && (
        <SweetTooth className="medium" />
      )}
      {splashTheme === SplashTheme.SPOILED && <Spoiled className="medium" />}
      {splashTheme === SplashTheme.TRASH && <Trash className="medium" />}
    </div>
  );
}
