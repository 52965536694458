import { Avatar, Button } from "src/ui/components";
import { TooltipDirection } from "src/ui/components/Tooltip/Tooltip.types";
import { Tooltip } from "src/ui/components";
import "./LoggedIn.styles.scss";
import { useLogout } from "src/hooks";

export default function LoggedInMenu({ ...user }) {
  const { logoutUser } = useLogout();
  return (
    <div className="loggedInMenu" data-testid="loggedInMenu">
      {/* <Button buttonText="Logout" classNames="logout" onClick={logoutUser} /> */}
      <Tooltip message={user?.name} direction={TooltipDirection.BOTTOM}>
        <Avatar to="/profile" userName={user?.name} />
      </Tooltip>
    </div>
  );
}
