import ChatMessagesProps from "./Messages.types";
import { Chat } from "src/ui/components";
import "./Messages.styles.scss";

export default function Messages({ messages, user }: ChatMessagesProps) {
  return messages && messages.length > 0 ? (
    <div className="chatMessages" data-testid="chatMessages">
      {messages.map(({ createdAt, message, senderName }, index) => (
        <Chat.Window.Message
          key={index}
          createdAt={createdAt}
          message={message}
          senderName={senderName}
          user={user}
        />
      ))}
    </div>
  ) : (
    <div className="noMessages">No chat messages</div>
  );
}
