import EllipseProps from "./Ellipse.types";
import "./Ellipse.styles.scss";

export default function Ellipse({
  classNames,
  length = 10,
  text,
}: EllipseProps) {
  if (typeof text !== "string" || text.length < length)
    return <div>{text}</div>;
  const subStr = text.substring(0, length);
  const className = classNames ? `ellipse ${classNames}` : "ellipse";
  return (
    <div className={className}>
      <div className="ellipsed" data-testid="ellipse">
        <span>{`${subStr}...`}</span>
      </div>
    </div>
  );
}
