import { useParams } from "react-router-dom";
import { useGetUserQuery } from "src/redux/api/user.api";
import { Forms } from "../..";
import "./User.styles.scss";
import { Ellipse } from "src/ui/components";

export default function User() {
  const { userId } = useParams();
  const { data: profile } = useGetUserQuery(userId!);

  return profile ? (
    <table className="userEditForm">
      <tbody>
        <tr>
          <td>User Id:</td>
          <td>{profile._id}</td>
        </tr>
        <tr>
          <td>Name:</td>
          <td>
            {<Ellipse classNames="userEmail" text={profile.name} length={20} />}
          </td>
        </tr>
        <tr>
          <td>Email:</td>
          <td>
            {
              <Ellipse
                classNames="userEmail"
                text={profile.email}
                length={20}
              />
            }
          </td>
        </tr>
        <tr>
          <td colSpan={2} style={{ textAlign: "center" }}>
            <Forms.EditRoleForm
              userId={userId || ""}
              redirect="/users"
              roles={profile!.roles}
            />
          </td>
        </tr>
      </tbody>
    </table>
  ) : null;
}
