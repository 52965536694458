import SidebarProps from "./Sidebar.types";
import { Top, Main, Bottom } from "./components";
import TopProps from "./components/Top/Top.types";
import MainProps from "./components/Main/Main.types";
import BottomProps from "./components/Bottom/Bottom.types";
import "./Sidebar.styles.scss";

function Sidebar({ children, toggle }: SidebarProps) {
  const className = toggle ? `sidebar open` : "sidebar";
  return (
    <div className={className} data-testid="sidebar">
      {children}
    </div>
  );
}

Sidebar.Top = function ({ children }: TopProps) {
  return children ? <Top>{children}</Top> : null;
};

Sidebar.Main = function ({ children }: MainProps) {
  return children ? <Main>{children}</Main> : null;
};

Sidebar.Bottom = function ({ children }: BottomProps) {
  return children ? <Bottom>{children}</Bottom> : null;
};

export default Sidebar;
