import SearchbarProps from "./Searchbar.types";
import "./Searchbar.styles.scss";

// ARTICLE: https://stackoverflow.com/questions/68330104/how-to-implement-rtks-createapi-query-for-debouncing
// ARTICLE: https://codesandbox.io/p/sandbox/rtk-query-debounce-example-ep8zp?file=%2Fsrc%2FBookSearchResults.tsx%3A13%2C5-13%2C23

export default function Searchbar({
  classNames,
  onChange,
  placeholder,
  value,
}: SearchbarProps) {
  classNames = classNames ? `input ${classNames}` : "input";
  return (
    <form className="searchbar" data-testid="searchbar" autoComplete="off">
      <input
        name="search"
        className={classNames}
        onChange={onChange}
        placeholder={placeholder}
        type="search"
        value={value}
      />
    </form>
  );
}
