import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useToggle } from "src/hooks";
import { Menus, Modals } from "src/ui/features";
import { components } from "./components";
import { Spoof } from "./components/Spoof";
import "./Splash.styles.scss";

export default function Splash() {
  const splashTheme = useSelector((state: any) => state.uiState.splashTheme);
  const [logoSwitch, setLogoSwitch] = useState(0);
  const [, setShowTheme] = useState(false);
  const { handleSetToggle, toggle } = useToggle(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [showTosModal, setShowTosModal] = useState(false);
  function openMenu() {
    handleSetToggle(true);
    document.body.className = "noscroll";
  }
  function closeMenu() {
    if (toggle === true) {
      handleSetToggle(false);
      document.body.className = "";
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setLogoSwitch(0);
    }, 5000);
  }, [logoSwitch]);

  function handleSetShowTheme(show: boolean) {
    setShowTheme(show);
  }

  return (
    <div className={`splash ${splashTheme}`}>
      <components.ThemeSwitch handleSetShowTheme={handleSetShowTheme} />
      <Modals.ContactModal
        showModal={showContactModal}
        handleShowContactModal={() => setShowContactModal(false)}
      />
      <Modals.TosModal
        showModal={showTosModal}
        handleShowModal={() => setShowTosModal(false)}
      />
      <Menus.Splash handleToggle={closeMenu} toggle={toggle} />
      <>
        <Spoof.Logo splashTheme={splashTheme} />
        <components.Header openMenu={openMenu} />
        <components.Sections.Section1 splashTheme={splashTheme} />
        <components.Sections.Section2 />
        <components.Sections.Section6 />
        <components.Tickers.Five />
        <components.Sections.Section3 />
        <components.Tickers.Three />
        <components.Sections.Section4 />
        <components.Tickers.Two />
        <components.Sections.Section5 />
        <components.Tickers.Four />
        <components.Sections.Section8 />
        <components.Footer
          handleShowContactModal={() => setShowContactModal(true)}
          handleShowTosModal={() => setShowTosModal(true)}
          splashTheme={splashTheme}
        />
      </>
    </div>
  );
}
