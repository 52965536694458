import PromptProps from "./Prompt.types";
import "./Prompt.styles.scss";

export default function Prompt({ prompt }: PromptProps) {
  return (
    <div className="prompt">
      <div>{prompt}</div>
    </div>
  );
}
