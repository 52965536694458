import { Spoof } from "../../Spoof";
import { ReactComponent as ExplicitContent } from "src/assets/splash/explicitContent.svg";
import { Tickers } from "../../Tickers";
import "./Section1.styles.scss";

export default function Section1({ splashTheme }: { splashTheme: string }) {
  return (
    <div className="section1">
      <ExplicitContent className="explicitContent" />
      <Spoof.Duckboi splashTheme={splashTheme} />
      <Tickers.One />
    </div>
  );
}
