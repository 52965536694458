import MiddleProps from "./Middle.types";
import "./Middle.styles.scss";

export default function Middle({ children }: MiddleProps) {
  return (
    <div className="middle" data-testid="middle">
      {children}
    </div>
  );
}
