import { RatingForm } from "src/ui/features/Forms";
import { PageTitle } from "src/ui/components";
import { Layouts } from "../components";

export default function RatingPage() {
  return (
    <Layouts.DefaultPage>
      <PageTitle pageTitle="Rate Ludwig" />
      <RatingForm />
    </Layouts.DefaultPage>
  );
}
