import { Icon, Tooltip } from "src/ui/components";
import { faExternalLink } from "@fortawesome/free-solid-svg-icons";
import DuckboiAvatar from "src/assets/splash/duckboi.avatar.gif";
import "./Tokenomics.styles.scss";
import { TooltipDirection } from "src/ui/components/Tooltip/Tooltip.types";

export default function Tokenomics() {
  const contractAddress = "ADbV9hZMkqQRU5hw163Zq8Hid9exHnWsYm5aH8MRpump";
  const firstTenChars = contractAddress.slice(0, 10);
  const lastTenChars = contractAddress.slice(-10);
  return (
    <div className="tokenomics" data-testid="tokenomics">
      <div>
        <img
          alt="duckboi flying to the moon"
          src={DuckboiAvatar}
          width="500"
          height="500"
        />
      </div>
      <div className="contractAddress">
        <h1>Contract Address</h1>
        <p>
          <Tooltip
            message={contractAddress}
            direction={TooltipDirection.BOTTOM}
          >
            <span>{`${firstTenChars}...${lastTenChars}`}</span>
          </Tooltip>
          <a
            className="pumpFunLink"
            rel="noreferrer"
            target="_blank"
            href="https://pump.fun/coin/ADbV9hZMkqQRU5hw163Zq8Hid9exHnWsYm5aH8MRpump"
          >
            <Icon icon={faExternalLink} size="1x" />
          </a>
        </p>
      </div>
      <div>
        <h2>Fair launch</h2>
        <p>pump.fun</p>
      </div>
      <div>
        <h2>1B</h2>
        <p>Total Supply</p>
      </div>
      <div>
        <h2>LP</h2>
        <p>Locked 4ever</p>
      </div>
      <div>
        <h2>Ownership</h2>
        <p>Renounced</p>
      </div>
    </div>
  );
}
