import One from "./One";
import Two from "./Two";
import Three from "./Three";
import Four from "./Four";
import Five from "./Five";
export const Tickers = {
  One,
  Two,
  Three,
  Four,
  Five,
};
