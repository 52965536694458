import { IUser } from "src/types/IUser.types";
import "./UserDetails.styles.scss";

export default function UserDetails({ email, name, rating }: Partial<IUser>) {
  return (
    <div className="userDetails" data-testid="userDetails">
      <div>
        <div>Username:</div>
        <div>Email:</div>
        {(rating === 0 || rating) && <div>Rating:</div>}
      </div>
      <div>
        <div>{name}</div>
        <div>{email}</div>
        {(rating === 0 || rating) && <div>{rating}</div>}
      </div>
    </div>
  );
}
