// import { HomeCarousel } from "src/ui/features/Carousels";
import { Layouts } from "../components";
import "./Home.styles.scss";

export default function HomePage() {
  return (
    <div className="degenHomepage">
      <Layouts.DefaultPage>
        {/* <HomeCarousel /> */}
        <video autoPlay muted loop id="myVideo">
          <source src="/assets/degenCult/D3Cult.mp4" type="video/mp4" />
        </video>
      </Layouts.DefaultPage>
    </div>
  );
}
