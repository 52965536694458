export enum TooltipDirection {
  BOTTOM = "bottom",
  LEFT = "left",
  RIGHT = "right",
  TOP = "top",
  TOP_LEFT = "topLeft",
  TOP_RIGHT = "topRight",
}
export default interface TooltipProps {
  children?: JSX.Element[] | JSX.Element;
  classNames?: string;
  delay?: number;
  direction?: TooltipDirection;
  message: string;
}
