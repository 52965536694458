import TopProps from "./Top.types";
import "./Top.styles.scss";

export default function Top({ children }: TopProps) {
  return (
    <div className="top" data-testid="top">
      {children}
    </div>
  );
}
