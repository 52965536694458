import { useEffect, useState } from "react";

const COUNTDOWN = 120;

export default function useCountdown() {
  const [countdown, setCountdown] = useState(COUNTDOWN);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    let interval = setInterval(() => {
      setCountdown((lastTimerCount) => {
        lastTimerCount <= 1 && clearInterval(interval);
        if (lastTimerCount <= 1) {
          setDisabled(false);
        }
        if (lastTimerCount <= 0) return lastTimerCount;
        return lastTimerCount - 1;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [disabled]);

  return {
    countdown,
    disabled,
  };
}
