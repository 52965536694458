import Countdown from "./Countdown";
import Footer from "./Footer";
import Header from "./Header";
import Rng from "./Rng";
import { Sections } from "./Sections";
import Slider from "./Slider";
import { Spoof } from "./Spoof";
import Team from "./Team";
import ThemeSwitch from "./ThemeSwitch";
import { Tickers } from "./Tickers";
import Tokenomics from "./Tokenomics";

export const components = {
  Countdown,
  Footer,
  Header,
  Rng,
  Sections,
  Slider,
  Spoof,
  Team,
  ThemeSwitch,
  Tickers,
  Tokenomics,
};
