import { Forms } from "src/ui/features";
import { ReactComponent as DuckboiDegenDungeonCult } from "src/assets/degenDungeonCult/dddc.svg";
import "./Section3.styles.scss";

export default function Section3() {
  return (
    <div className="section3">
      <div>
        <DuckboiDegenDungeonCult className="duckboiDegenDungeonLogo" />
      </div>
      <div>
        <h2>JOIN THE CULT</h2>
        <Forms.JoinNowForm />
      </div>
    </div>
  );
}
