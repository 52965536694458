import "./Ticker.styles.scss";

export default function Slider({
  children,
  classNames,
  reverse = false,
}: {
  children?: JSX.Element[] | JSX.Element;
  classNames?: string;
  reverse?: boolean;
}) {
  const splashTicksClass = reverse ? "splashTicks reverse" : "splashTicks";
  classNames = classNames ? `ticker ${classNames}` : "ticker";
  return (
    <div className={classNames}>
      <div className={splashTicksClass}>{children}</div>
      <div className={splashTicksClass}>{children}</div>
    </div>
  );
}
