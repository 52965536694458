import Slider from "../../Slider";
import "./Section4.styles.scss";

export default function Section4() {
  return (
    <div id="section4" className="section4">
      <Slider />
    </div>
  );
}
