import { Profiles } from "src/ui/features";
import { Layouts } from "../components";
import { Chat } from "src/ui/components";
export default function UserPage() {
  return (
    <Layouts.DefaultPage>
      <Profiles.User />
      <Chat.Window.Chat />
    </Layouts.DefaultPage>
  );
}
