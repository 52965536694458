import { UserRoles } from "../../User/User.types";

export const SPLASH_MENU_LINKS = [
  {
    name: "The Whitepaper",
    to: "/#section2",
    roles: UserRoles.USER,
  },
  {
    name: "Join the cult",
    to: "/#section3",
    roles: UserRoles.USER,
  },
  {
    name: "Tokenomics",
    to: "/#section5",
    roles: UserRoles.USER,
  },
  {
    name: "Degen Dungeon Cult",
    to: "/degen_dungeon_cult",
    roles: UserRoles.USER,
  },
];
