import { Chat } from "src/ui/components";
import "./Chat.styles.scss";

export default function ChatsSidebar() {
  return (
    <div className="sidebar chat" data-testid="sidebar chat">
      <Chat.List />
    </div>
  );
}
