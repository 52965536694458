import { useAppSelector } from "src/redux/store";
import {
  useDeleteChatMutation,
  useGetChatsQuery,
} from "src/redux/api/chat.api";
import { Link } from "react-router-dom";
import { Button, Ellipse, Icon, Placeholder } from "src/ui/components";
import "./List.styles.scss";
import { faChevronRight, faClose } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

export default function List() {
  const [deleteChat] = useDeleteChatMutation();
  const [editMode, setEditMode] = useState(false);
  const { data } = useGetChatsQuery();
  const user = useAppSelector((state) => state.profileState.profile);
  const filterReceiver = (sender: string, values: string[]): string =>
    values.find((value) => value !== sender) || "";
  const removeChat = (e: any, chatId: number) => {
    e.preventDefault();
    e.stopPropagation();
    deleteChat(chatId);
  };
  function handleSetEditMode() {
    setEditMode(!editMode);
  }

  return data && data.length > 0 && user ? (
    <div className="chatListWrapper">
      <Button
        buttonText={editMode ? "Done" : "Edit"}
        classNames="editChat"
        onClick={handleSetEditMode}
      />
      <div className="chatList" data-testid="chatList">
        {data.map(({ _id, members, memberNames }: any) => (
          <Link key={_id} to={`/chat/${filterReceiver(user!._id, members)}`}>
            <Ellipse
              text={filterReceiver(user!.name, memberNames)}
              length={20}
            />
            <div>
              {editMode ? (
                <Icon
                  icon={faClose}
                  handleClick={(e: any) => removeChat(e, _id)}
                />
              ) : (
                <Icon icon={faChevronRight} />
              )}
            </div>
          </Link>
        ))}
      </div>
    </div>
  ) : (
    <Placeholder text="0 Chats" />
  );
}
