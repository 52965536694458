import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAdminState } from "src/types/IAdmin.types";

const initialState: IAdminState = {
  admin: {
    userId: null,
  },
};

export const adminSlice = createSlice({
  initialState,
  name: "adminSlice",
  reducers: {
    setUser: (state, action: PayloadAction<string>) => {
      state.admin = { ...state.admin, userId: action.payload };
    },
  },
});

export default adminSlice.reducer;

export const { setUser } = adminSlice.actions;
