import { Outlet } from "react-router-dom";
import { Chat, Layout, Page, Placeholder } from "src/ui/components";
import { Layouts, Searchbars, Sidebars } from "src/ui/features";

export default function ChatPage() {
  return (
    <Layout>
      <Layouts.Header />
      <Layout.Middle>
        <Layouts.LeftSidebar title="Chats">
          <Sidebars.Chat />
        </Layouts.LeftSidebar>
        <Layout.Center>
          <Page classNames="chat">
            <Page.Top>
              <Searchbars.User />
            </Page.Top>
            <Page.Main>
              <Outlet />
            </Page.Main>
            <Page.Bottom>
              <Chat.Input />
            </Page.Bottom>
          </Page>
        </Layout.Center>
      </Layout.Middle>
    </Layout>
  );
}
