import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppSelector } from "src/redux/store";
import { useGetMessagesQuery } from "src/redux/api/chat.api";
import { Chat } from "src/ui/components";
import "./Body.styles.scss";

export default function ChatWindowBody() {
  const [, setMessages] = useState([]);
  const { userId } = useParams();
  const { data } = useGetMessagesQuery(userId);
  const { user } = useAppSelector((state) => state.userState);
  const messages = useAppSelector((state) => state.chatState.messages);
  useEffect(() => {
    setMessages(data);
  }, [data, userId]);
  return (
    <div className="chatBody">
      <Chat.Window.Messages messages={messages} user={user} />
    </div>
  );
}
