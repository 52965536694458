import Countdown from "../../Countdown";
import "./Section6.styles.scss";

export default function Section4() {
  return (
    <div id="section3_1" className="countdownSection">
      <div>$DUCKBOI / 12.12.24</div>
      <div>
        <Countdown launchDate="Dec 12, 2024 12:12:00" />
      </div>
    </div>
  );
}
