import { useAppSelector } from "src/redux/store";
import { Icons, Logos, Menus, Toolbars } from "src/ui/features";
import { Layout } from "src/ui/components";
import { useToggle } from "src/hooks";
import { useCookies } from "react-cookie";

export default function Header() {
  const user = useAppSelector((state) => state.profileState.profile);
  const [cookies] = useCookies(["logged_in"]);
  const { handleSetToggle, toggle } = useToggle(false);
  return (
    <>
      <Menus.Site handleToggle={handleSetToggle} toggle={toggle} />
      <Layout.Top>
        <Layout.Middle>
          <Layout.Left>
            <Logos.Degen />
          </Layout.Left>
          <Layout.Center>
            {cookies && cookies.logged_in ? (
              <Menus.LoggedIn {...user} />
            ) : (
              <Menus.LoggedOut />
            )}
          </Layout.Center>
          <Layout.Right>
            <Icons.MenuIcon callback={() => handleSetToggle(true)} />
          </Layout.Right>
        </Layout.Middle>
      </Layout.Top>
      <Toolbars.Site />
    </>
  );
}
