import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { Icon } from "src/ui/components";
import IconsProps from "../Icons.types";
import "./BackArrow.styles.scss";

export default function BackArrowIcon({ callback }: IconsProps) {
  return (
    <Icon
      classNames="icon backarrow"
      icon={faChevronLeft}
      handleClick={callback}
      size="sm"
    />
  );
}
