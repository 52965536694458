import { useEffect, useRef, useState } from "react";
import useOtpLogin from "./useOtpLogin";

export default function useOtp(isExpired: boolean) {
  const { onSubmit } = useOtpLogin();
  const inputRef = useRef<HTMLInputElement | undefined>();
  const [otp, setOtp] = useState<string[]>([]);
  const handleEnterPasscode = (e: any) => {
    if (isExpired) return;
    if (e.keyCode > 47 && e.keyCode < 58) {
      setOtp([...otp, e.key]);
    }
  };
  const handleNonPrintable = (e: any) => {
    if (inputRef.current && inputRef.current.value && e.key === "Backspace") {
      setOtp([]);
      inputRef.current!.value = "";
    }
  };
  useEffect(() => {
    window.addEventListener("keypress", handleEnterPasscode);
    return () => {
      window.removeEventListener("keypress", handleEnterPasscode);
    };
  });

  useEffect(() => {
    window.addEventListener("keydown", handleNonPrintable);
    return () => {
      window.removeEventListener("keydown", handleNonPrintable);
    };
  });

  useEffect(() => {
    if (otp.length === 6) {
      onSubmit(Number(otp.join("")));
      setOtp([]);
    }
  }, [otp, onSubmit]);

  return {
    inputRef,
    otp,
  };
}
