import Ticker from "../../Ticker";

export default function Two() {
  return (
    <Ticker reverse={true} classNames="two">
      <div className="splashTick">
        <span>H0DL 817CH35</span>
      </div>
      <div className="splashTick">
        <span>83 4 8ULL15H FUCK3R</span>
      </div>
      <div className="splashTick">
        <span>D1R7Y 571NKY D363N</span>
      </div>
      <div className="splashTick">
        <span>KW4K KW4K FUD</span>
      </div>
      <div className="splashTick">
        <span>LF6 KW4K LF6</span>
      </div>
      <div className="splashTick">
        <span>F0M0 KW4K K1LLzZZ</span>
      </div>
      <div className="splashTick">
        <span>KW4K FUK FUK</span>
      </div>
      <div className="splashTick">
        <span>KW4K KW4K KW4K</span>
      </div>
      <div className="splashTick">
        <span>5H1LL 5H1LL 5H1LL</span>
      </div>
    </Ticker>
  );
}
