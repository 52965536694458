import ContextMenuProps from "./ContextMenu.types";
import "./ContextMenu.styles.scss";

export default function ContextMenu({
  children,
  classNames,
}: ContextMenuProps) {
  const className = classNames ? `contextMenu ${classNames}` : "contextMenu";
  return (
    <div className={className} data-testid="contextMenu">
      {children}
    </div>
  );
}
