import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { Middleware } from "src/middleWare";
import adminSlice from "./features/admin.slice";
import { authApi } from "./api/auth.api";
import authSlice from "./features/auth.slice";
import { chatApi } from "./api/chat.api";
import chatReducer from "./features/chat.slice";
import { profileApi } from "./api/profile.api";
import uiSlice from "./features/ui.slice";
import { userApi } from "./api/user.api";
import userSlice from "./features/user.slice";
import { usersApi } from "./api/users.api";
import usersSlice from "./features/users.slice";
import profileSlice from "./features/profile.slice";
import { memberApi } from "./api/member.api";
import { contactApi } from "./api/contact.api";

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [chatApi.reducerPath]: chatApi.reducer,
    [contactApi.reducerPath]: contactApi.reducer,
    [memberApi.reducerPath]: memberApi.reducer,
    [profileApi.reducerPath]: profileApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    adminState: adminSlice,
    authState: authSlice,
    chatState: chatReducer,
    profileState: profileSlice,
    uiState: uiSlice,
    userState: userSlice,
    usersState: usersSlice,
  },
  devTools: process.env.NODE_ENV === "development",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({}).concat([
      Middleware.AlertLoggerMiddleware,
      authApi.middleware,
      chatApi.middleware,
      contactApi.middleware,
      memberApi.middleware,
      profileApi.middleware,
      userApi.middleware,
      usersApi.middleware,
    ]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
