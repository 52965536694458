import { Layout, Sidebar } from "src/ui/components";
import { useToggle } from "src/hooks";
import { SidebarToggleIcons } from "src/ui/features/Icons";
import LeftSidebarProps from "./LeftSidebar.types";

export default function LeftSidebar({ children, title }: LeftSidebarProps) {
  const { handleSetToggle, toggle } = useToggle(true);
  return (
    <Layout.Left>
      <Sidebar toggle={toggle}>
        <Sidebar.Top>{title ? <h3>{title}</h3> : undefined}</Sidebar.Top>
        <Sidebar.Main>{children}</Sidebar.Main>
        <Sidebar.Bottom>
          <SidebarToggleIcons.Left
            callback={() => handleSetToggle()}
            toggle={toggle}
          />
        </Sidebar.Bottom>
      </Sidebar>
    </Layout.Left>
  );
}
