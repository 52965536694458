import ContextMenuItemProps from "./ContextMenuItem.types";
import "./ContextMenuItem.styles.scss";
import { Link } from "react-router-dom";

export default function ContextMenuItem({
  children,
  onClick,
  text,
  to,
}: ContextMenuItemProps) {
  if (onClick) {
    return (
      <div
        className="contextMenuItem"
        data-testid="contextmenuitem"
        onClick={onClick}
      >
        {children ? (
          <ul>
            <li>{children}</li>
            <li>{text}</li>
          </ul>
        ) : (
          text
        )}
      </div>
    );
  } else if (to) {
    return (
      <Link className="contextMenuItem" to={to}>
        {children ? (
          <ul>
            <li>{children}</li>
            <li>{text}</li>
          </ul>
        ) : (
          text
        )}
      </Link>
    );
  } else {
    return (
      <div className="contextMenuItem" data-testid="contextmenuitem">
        {children ? (
          <ul>
            <li>{children}</li>
            <li>{text}</li>
          </ul>
        ) : (
          text
        )}
      </div>
    );
  }
}
