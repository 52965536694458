import Simple from "./SimpleLayout";
import ChatLayout from "./ChatLayout";
import DefaultPage from "./DefaultPageLayout";
import ProfilePage from "./ProfilePageLayout";
export const Layouts = {
  Simple,
  ChatLayout,
  DefaultPage,
  ProfilePage,
};
