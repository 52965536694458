import Chat from "./Window";
import Body from "./components/Body";
import Footer from "./components/Footer";
import {
  Input,
  Message,
  Messages,
  Collapsed,
  Expanded,
  Header,
} from "./components";

export const Window = {
  Body,
  Chat,
  Collapsed,
  Expanded,
  Footer,
  Header,
  Input,
  Message,
  Messages,
};
