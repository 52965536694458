import { getCopyrightYear } from "src/utils/date.utils";
import { Logos } from "src/ui/features";
import "./SiteCopyright.styles.scss";

export default function SiteCopyright() {
  return (
    <div className="siteCopyright">
      <div>
        <Logos.Degen classNames="small" />
      </div>
      <div>
        <b>{`${getCopyrightYear()} Duckboi Degen Dungeon Cult`}</b>
      </div>
    </div>
  );
}
