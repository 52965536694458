import { usePreloader } from "src/hooks";
import { useContactMutation } from "src/redux/api/contact.api";

export default function useContact() {
  const [contact, { isLoading, isSuccess }] = useContactMutation();
  const isPreloaderSuccess = isLoading && !isSuccess;
  const onSubmit = (data: any) => contact(data);

  usePreloader(isPreloaderSuccess);
  return {
    onSubmit,
    isSuccess,
  };
}
