import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Chat } from "src/ui/components";
import { useGetMessagesQuery } from "src/redux/api/chat.api";
import { useAppSelector } from "src/redux/store";
import "./Messages.styles.scss";

export default function Messages() {
  const [, setMessages] = useState([]);
  const { userId } = useParams();
  const { data } = useGetMessagesQuery(userId);
  const profile = useAppSelector((state) => state.profileState.profile);
  const messages = useAppSelector((state) => state.chatState.messages);

  useEffect(() => {
    setMessages(data);
  }, [data, userId]);

  return messages && messages.length > 0 ? (
    <Chat.Messages messages={messages} profile={profile} />
  ) : (
    <div className="messagesPlaceholder">
      <Chat.ProfileBanner />
    </div>
  );
}
