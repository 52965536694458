import { useState } from "react";

export default function useToggle(initialToggleState: boolean) {
  const [toggle, setToggle] = useState(initialToggleState);

  function handleSetToggle(_toggle?: boolean) {
    if (_toggle) {
      setToggle(_toggle);
    } else {
      setToggle(!toggle);
    }
  }
  return {
    handleSetToggle,
    toggle,
  };
}
