import { useToggle, Redirect, useLogin } from "src/hooks";
import {
  Form,
  FormFooter,
  FORM_NAMES,
  Input,
  SiteLink,
  Wrapper,
} from "src/ui/components";
import { Buttons } from "src/ui/features";
import { TogglePasswordIcon } from "src/ui/features/Icons";
import { defaultValues, validationSchema } from "./Login.schema";
import "./Login.styles.scss";

export default function LoginForm() {
  const { handleSetToggle, toggle } = useToggle(true);
  const { onSubmit } = useLogin();
  return (
    <Wrapper
      classNames="auth"
      dataTestId="loginForm"
      headerText={FORM_NAMES.LOGIN}
    >
      <Form
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        validation={validationSchema}
      >
        <Input name="email" placeholder="Email Address" inputType="email" />
        <Input
          autocomplete="on"
          name="password"
          placeholder="Password"
          inputType={toggle ? "password" : "text"}
        />
        <TogglePasswordIcon
          callback={() => handleSetToggle()}
          toggle={toggle}
        />
        <FormFooter classNames="form">
          <div className="formFooterLinks">
            <SiteLink
              classNames="link"
              linkText="Register"
              destination={Redirect.REGISTER}
            />
            <SiteLink
              classNames="link"
              linkText="Forgot Password"
              destination={Redirect.FORGOT_PASSWORD}
            />
          </div>
          <div>
            <Buttons.Button buttonText="Login" classNames="login" />
          </div>
        </FormFooter>
      </Form>
    </Wrapper>
  );
}
