import { useRegister, useToggle } from "src/hooks";
import { Form, FormFooter, Input, SiteLink, Wrapper } from "src/ui/components";
import { FORM_NAMES } from "src/ui/components/Form/Form.constants";
import { Buttons } from "src/ui/features";
import { defaultValues, validationSchema } from "./Register.schema";
import { TogglePasswordIcon } from "../../Icons";

export default function RegisterForm() {
  const { handleSetToggle, toggle } = useToggle(true);
  const togglePasswordType = toggle ? "password" : "text";
  const { onSubmit } = useRegister();
  return (
    <Wrapper
      classNames="auth"
      dataTestId="registerForm"
      headerText={FORM_NAMES.REGISTER}
    >
      <Form
        data-testid="registerForm"
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        validation={validationSchema}
      >
        <Input name="name" placeholder="User Name" inputType="text" />
        <Input name="email" placeholder="Email Address" inputType="email" />
        <Input
          name="password"
          placeholder="Password"
          inputType={togglePasswordType}
        />
        <TogglePasswordIcon
          callback={() => handleSetToggle()}
          toggle={toggle}
        />
        <Input
          name="passwordConfirm"
          placeholder="Confirm Password"
          inputType={togglePasswordType}
        />
        <FormFooter classNames="form">
          <div>
            <SiteLink classNames="link" linkText="Login" destination="/login" />
          </div>
          <div>
            <Buttons.Button buttonText="Register" classNames="register" />
          </div>
        </FormFooter>
      </Form>
    </Wrapper>
  );
}
