export const PAGE_SIZE = 8;

export const MAX_CELL_TEXT_LENGTH = 8;

export const USER_TABLE_COLUMNS = [
  { name: "" },
  { name: "_id" },
  { name: "name", sort: true, type: "string" },
  { name: "email", sort: true, type: "string" },
  { name: "roles" },
  { name: "createdAt", sort: true, type: "number" },
  { name: "updatedAt", sort: true, type: "number" },
  { name: "lastLoggedIn", sort: true, type: "number" },
  { name: "rating", sort: true, type: "number" },
];

export const DROPDOWN_MENUS = {
  TABLE_TOOLBAR_FILTER: [
    { name: "name", sort: true, type: "string" },
    { name: "email", sort: true, type: "string" },
  ],
  USER_CONTEXT: (userId: any, editCallback?: () => void) => [
    { name: "Close Menu", isClose: true },
    { name: "View User Profile", to: `/user/${userId}` },
    { name: "Edit", callback: editCallback },
    { name: "Manage Roles" },
    { name: "Delete", to: `/user/delete/${userId}` },
  ],
};

export enum MENU_TYPES {
  FILTER = "filter",
  CONTEXT = "context",
}
