import IconMenu from "./IconMenu";
import Chat from "./Chat";
import LoggedIn from "./LoggedIn";
import LoggedOut from "./LoggedOut";
import Profile from "./Profile";
import Site from "./Site";
import Splash from "./Splash";
import EditUser from "./EditUser";
import { SIDE_MENUS } from "./IconMenu/IconMenu.constants";
import Social from "./Social";
export const Menus = {
  Chat,
  LoggedIn,
  LoggedOut,
  Profile,
  Site,
  Social,
  Splash,
  EditUser,
};
export { IconMenu, LoggedOut, SIDE_MENUS };
