import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUpdateUserRolesMutation } from "src/redux/api/users.api";
import { Redirect } from "src/hooks";
import { FormFooter, MultiSelect, SiteLink, Wrapper } from "src/ui/components";
import { toggleOption } from "src/ui/components/MultiSelect/MultiSelect.utils";
import { Buttons } from "src/ui/features";
import { MULTI_SELECT_OPTIONS } from "./Role.constants";
import RoleProps from "./Role.types";
import "./Role.styles.scss";

export default function EditRoleForm({ redirect, roles, userId }: RoleProps) {
  const navigate = useNavigate();
  const [updateUserRoles, { isSuccess }] = useUpdateUserRolesMutation();
  const [selected, setSelected] = useState<string[]>([]);
  const handleToggleOption = toggleOption({ callback: setSelected });

  useEffect(() => {
    roles && setSelected(roles);
    if (isSuccess) {
      navigate(redirect);
    }
  }, [isSuccess, navigate, redirect, roles]);

  function handleSubmit(event: any) {
    event.preventDefault();
    updateUserRoles({ id: userId, roles: selected });
  }

  return roles && roles.length > 0 ? (
    <Wrapper classNames="editRole" dataTestId="editRoleForm">
      <form className="role" data-testid="role" onSubmit={handleSubmit}>
        <MultiSelect
          options={MULTI_SELECT_OPTIONS}
          selected={selected}
          toggleOption={handleToggleOption}
        />
        <FormFooter classNames="form">
          <>
            <SiteLink
              classNames="link"
              linkText="Cancel"
              destination={Redirect.USERS}
            />
          </>
          <>
            <Buttons.Button buttonText="Update" classNames="updateUser" />
          </>
        </FormFooter>
      </form>
    </Wrapper>
  ) : null;
}
