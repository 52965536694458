import { useAppSelector } from "src/redux/store";
import { Forms } from "src/ui/features";

export default function EditRolePage() {
  const profile = useAppSelector((state) => state.profileState.profile)!;

  return (
    <Forms.EditRoleForm
      userId={profile?._id}
      redirect="/profile"
      roles={profile?.roles}
    />
  );
}
