import { object, string, InferType } from "yup";

export const defaultValues = {
  email: "",
};

export const validationSchema = object().shape({
  email: string().required().email("Must be a valid email"),
});

export type ForgotPasswordPayloadType = InferType<typeof validationSchema>;
