import { useState } from "react";
import { Collapsed, Expanded } from "./components";
import "./Window.styles.scss";

export default function ChatWindow() {
  const [isClosed, setIsClosed] = useState(false);
  const [isMinimized, setIsMinimized] = useState(true);
  const [isMaximized, setIsMaximized] = useState(false);

  return !isMinimized ? (
    <Expanded
      handleClose={() => setIsClosed(true)}
      handleCollapse={() => setIsMinimized(true)}
      handleMaximize={() => setIsMaximized(!isMaximized)}
      isClosed={isClosed}
      isMaximized={isMaximized}
    />
  ) : (
    <Collapsed handleCollapse={() => setIsMinimized(false)} />
  );
}
