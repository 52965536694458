import { useMember } from "src/hooks";
import { Form, FormFooter, Input, Wrapper } from "src/ui/components";
import { Buttons } from "src/ui/features";
import { defaultValues, validationSchema } from "./JoinNow.schema";
import "./JoinNow.styles.scss";

export default function JoinNowForm() {
  const { onSubmit } = useMember();
  return (
    <Wrapper classNames="joinNowForm" dataTestId="registerForm">
      <Form
        data-testid="joinNowForm"
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        validation={validationSchema}
      >
        <Input name="name" placeholder="Nickname" inputType="text" />
        <Input
          name="walletAddress"
          placeholder="Wallet Address"
          inputType="text"
        />
        <Input name="email" placeholder="Email Address" inputType="email" />
        <FormFooter classNames="form">
          <Buttons.Button
            buttonText="ADD ME TO WAITLIST"
            classNames="joinNow"
          />
        </FormFooter>
      </Form>
    </Wrapper>
  );
}
