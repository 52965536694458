import { UserRoles } from "../../User/User.types";

export const SITE_MENU_LINKS = [
  {
    name: "Home",
    to: "/degen_dungeon_cult",
    roles: UserRoles.USER,
  },
  {
    name: "Duckboi",
    to: "/",
    roles: UserRoles.USER,
  },
];
