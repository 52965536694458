import ChangePasswordForm from "./ChangePassword";
import ContactForm from "./Contact";
import AddChatRoomForm from "./Chat/AddChatRoom";
import { EditProfileForm, EditRoleForm, EditUserForm } from "./Edit";
import ForgotPasswordForm from "./ForgotPassword";
import JoinNowForm from "./JoinNow/JoinNow";
import LoginForm from "./Login";
import RegisterForm from "./Register/Register";
import RatingForm from "./Rating";
export const Forms = {
  AddChatRoomForm,
  ContactForm,
  ChangePasswordForm,
  EditProfileForm,
  EditRoleForm,
  EditUserForm,
  ForgotPasswordForm,
  JoinNowForm,
  LoginForm,
  RatingForm,
  RegisterForm,
};
export {
  AddChatRoomForm,
  ChangePasswordForm,
  EditProfileForm,
  EditRoleForm,
  LoginForm,
  RatingForm,
  RegisterForm,
};
