import { V2 as V2Hooks } from "src/hooks";
import { useGetUsersQuery } from "src/redux/api/users.api";
import { Ellipse, Placeholder, Tooltip } from "src/ui/components";
import { TooltipDirection } from "src/ui/components/Tooltip/Tooltip.types";

const usePager = V2Hooks.usePager;

export default function Users() {
  const { data = [] } = useGetUsersQuery();
  const { paged } = usePager(data, 30);
  return (
    <div className="users" data-testid="users">
      {paged.length === 0 ? (
        <Placeholder text="0 Users" />
      ) : (
        <div className="scrollable">
          {paged.map(({ name }: { name: string }) => (
            <div key={name}>
              <Tooltip
                message={name}
                delay={0}
                direction={TooltipDirection.LEFT}
              >
                <Ellipse length={15} text={name} />
              </Tooltip>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
