import TableToolbarFilterDropdownProps from "./TableToolbarFilter.types";
import FilterInput from "src/ui/components/FilterInput";
import DropdownMenu from "src/ui/components/DropdownMenu";
import "./TableToolbarFilter.styles.scss";
import { MENU_TYPES } from "src/ui/components/Table/Table.constants";

export default function TableToolbarFilterDropdown({
  menuItems,
  filterTerm,
  handleToggle,
  onChange,
  onClick,
  toggle,
}: TableToolbarFilterDropdownProps) {
  return (
    <div className="tableToolbarFilterDropdown">
      <FilterInput
        filterTerm={filterTerm}
        handleToggle={handleToggle}
        onChange={onChange}
      />
      <DropdownMenu
        menuItems={menuItems}
        onClick={onClick}
        toggle={toggle}
        menuType={MENU_TYPES.FILTER}
      />
    </div>
  );
}
