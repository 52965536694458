import { Link } from "react-router-dom";
import SearchResultsProps from "./SearchResults.types";
import "./SearchResults.styles.scss";

export default function SearchResults({
  callback,
  linkPath,
  searchTerm,
}: SearchResultsProps) {
  const { data, error, isLoading, isFetching } = callback(searchTerm);

  if (error) {
    console.log("error", error);
    return (
      <ul className="searchResults">
        <li>Error while fetching users</li>
      </ul>
    );
  }

  if (isLoading) {
    return (
      <ul className="searchResults">
        <li>Loading users...</li>
      </ul>
    );
  }

  if (isFetching) {
    return (
      <ul className="searchResults">
        <li>Fetching users...</li>
      </ul>
    );
  }

  if ((data ?? []).length === 0) {
    return (
      <ul className="searchResults">
        <li className="noUsers">No users found</li>
      </ul>
    );
  }

  return (
    <ul className="searchResults">
      {(data ?? []).map(({ _id, name }: any) => (
        <li key={_id}>
          <Link to={`${linkPath}${_id}`}>{name}</Link>
        </li>
      ))}
    </ul>
  );
}
