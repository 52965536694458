import { useSelector } from "react-redux";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { ChatHistoryMenu, ChatsProps } from "./Chat.types";
import { Button, Icon, Wrapper } from "src/ui/components";
import { HistoryItem } from "./components";
import "./Chat.styles.scss";

export default function ChatMenu({ clickHandler }: ChatsProps) {
  const history = useSelector((state: any) => {
    const { gptState } = state;
    return gptState.history;
  });
  const messages = useSelector((state: any) => {
    const { gptState } = state;
    return gptState.messages;
  });

  return (
    <div className="chatMenu" data-testid="chatsMenu">
      <Button
        buttonText={ChatHistoryMenu.NEW_CHAT}
        classNames="addNewChat"
        onClick={clickHandler}
        // disabled={messages.length === 0}
      >
        <Icon icon={faPenToSquare} />
      </Button>
      {history.length > 0 ? (
        <>
          <h3>{ChatHistoryMenu.TITLE}</h3>
          <ul>
            {history.map(({ title }: { title: string }, index: number) => (
              <HistoryItem key={index} title={title} />
            ))}
          </ul>
        </>
      ) : (
        <Wrapper classNames="chatHistory">
          <div>{`No Chat History`}</div>
        </Wrapper>
      )}
    </div>
  );
}
