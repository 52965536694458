import { Layout, Page } from "src/ui/components";

export default function AuthPageLayout({
  children,
}: {
  children?: JSX.Element[] | JSX.Element;
}) {
  return (
    <Layout>
      <Layout.Middle>
        <Layout.Center>
          <Page classNames="center">
            <Page.Main>{children}</Page.Main>
          </Page>
        </Layout.Center>
      </Layout.Middle>
    </Layout>
  );
}
