import { ImageGrid, PageTitle, VENDOR_IMAGES } from "src/ui/components";
import { Layouts } from "../components";

export default function TechPage() {
  return (
    <Layouts.DefaultPage>
      <PageTitle pageTitle="The Tech" />
      <ImageGrid images={VENDOR_IMAGES} />
    </Layouts.DefaultPage>
  );
}
