import SocialProps from "./Social.types";
import { Icon } from "src/ui/components";
import "./Social.styles.scss";

export default function Social({ href, icon, size = "xl" }: SocialProps) {
  return (
    <a className="social" href={href} rel="noreferrer" target="_blank">
      <Icon icon={icon} size={size} />
    </a>
  );
}
