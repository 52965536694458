import { createSlice } from "@reduxjs/toolkit";
import { IChatState } from "src/redux/types";
const initialState: IChatState = {
  chat: null,
  messages: [],
  chats: [],
};

export const chatSlice = createSlice({
  initialState,
  name: "chatSlice",
  reducers: {
    loadChat: (state, action: any) => {
      state.chat = action.payload;
    },
    loadMessages: (state, action: any) => {
      state.messages = action.payload;
    },
    loadMessage: (state, action: any) => {
      state.messages = [...state.messages, action.payload];
    },
    loadChats: (state, action: any) => {
      state.chats = action.payload;
    },
  },
});

export const { loadChat, loadMessages, loadMessage, loadChats } =
  chatSlice.actions;

export default chatSlice.reducer;
