import { Redirect, useForgotPassword } from "src/hooks";
import {
  Form,
  FormFooter,
  FORM_NAMES,
  Input,
  SiteLink,
  Wrapper,
} from "src/ui/components";
import { Buttons } from "src/ui/features";
import { defaultValues, validationSchema } from "./ForgotPassword.schema";
import "./ForgotPassword.styles.scss";

export default function ForgotPasswordForm() {
  const { onSubmit } = useForgotPassword();
  return (
    <Wrapper
      classNames="auth"
      dataTestId="loginForm"
      headerText={FORM_NAMES.FORGOT_PASSWORD}
    >
      <Form
        classNames="auth"
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        validation={validationSchema}
      >
        <Input name="email" placeholder="Email Address" inputType="email" />
        <FormFooter classNames="form">
          <div className="formFooterLinks">
            <SiteLink
              classNames="link"
              linkText="Back to login"
              destination={Redirect.LOGIN}
            />
          </div>
          <div>
            <Buttons.Button buttonText="Send Email" classNames="login" />
          </div>
        </FormFooter>
      </Form>
    </Wrapper>
  );
}
