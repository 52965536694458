import { useAppSelector } from "src/redux/store";
import { useGetUsersQuery } from "src/redux/api/users.api";
import { SortableTable } from "src/ui/features/Tables";
import {
  PAGE_SIZE,
  MAX_CELL_TEXT_LENGTH,
  USER_TABLE_COLUMNS,
  DROPDOWN_MENUS,
} from "src/ui/components/Table/Table.constants";

export default function UsersTable() {
  useGetUsersQuery();
  const data = useAppSelector((state) => state.usersState.users);
  return (
    data && (
      <SortableTable
        columns={USER_TABLE_COLUMNS}
        data={data}
        maxCellTextLength={MAX_CELL_TEXT_LENGTH}
        pageSize={PAGE_SIZE}
        toolbarFilterMenu={DROPDOWN_MENUS.TABLE_TOOLBAR_FILTER}
      />
    )
  );
}
