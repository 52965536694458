import Team from "../../Team";
import "./Section7.styles.scss";

export default function Section7() {
  return (
    <div id="section7" className="section7">
      <h1 className="sectionTitle">The Team</h1>
      <Team />
    </div>
  );
}
