import classnames from "classnames";
import { ExpandedChatProps } from "../../Window.types";
import { Chat } from "src/ui/components";

export default function ExpandedChatWindow({
  isMaximized,
  isClosed,
  handleClose,
  handleMaximize,
  handleCollapse,
}: ExpandedChatProps) {
  return (
    <div
      className={classnames("chatWindow open", {
        max: isMaximized,
        close: isClosed,
      })}
      data-testid="chatWindow"
    >
      <Chat.Window.Header
        onClose={handleClose}
        onMinimize={handleCollapse}
        onMaximize={handleMaximize}
        isMaximized={isMaximized}
      />
      <Chat.Window.Body />
      <Chat.Window.Footer />
    </div>
  );
}
