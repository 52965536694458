import LeftProps from "./Left.types";
import "./Left.styles.scss";

export default function Left({ children }: LeftProps) {
  return (
    <div className="left" data-testid="left">
      {children}
    </div>
  );
}
