import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useLocation } from "react-router-dom";
import { SiteMenuCloseIcon } from "src/ui/features/Icons";
import { Backdrop, Button } from "src/ui/components";
import { Menus } from "src/ui/features";
import SiteMenuItem from "./components";
import { SITE_MENU_LINKS } from "./Site.constants";
import { useLogout } from "src/hooks";

import "./Site.styles.scss";

function SiteHeaderMenu({
  handleToggle,
}: {
  handleToggle: (toggle?: boolean) => void;
}) {
  return (
    <div className="siteMenuHeader" data-testid="siteHeaderMenu">
      <SiteMenuCloseIcon callback={handleToggle} />
    </div>
  );
}

function SiteMenuFooter() {
  return (
    <div className="siteMenuFooter" data-testid="siteHeaderMenu">
      <Menus.Social />
    </div>
  );
}

export default function SiteMenu({
  handleToggle,
  toggle,
}: {
  handleToggle: (toggle?: boolean) => void;
  toggle: boolean;
}) {
  const [cookies] = useCookies(["logged_in"]);
  const { logoutUser } = useLogout();
  const { pathname } = useLocation();
  useEffect(() => {}, [pathname]);
  const className = toggle ? "siteMenu show" : "siteMenu";
  return (
    <>
      <div className={className} data-testid="siteMenu">
        <SiteHeaderMenu handleToggle={() => handleToggle(false)} />
        <div className="siteMenuBody">
          {SITE_MENU_LINKS.map(({ name, roles, to }, index) => (
            <SiteMenuItem key={index} name={name} roles={roles} to={to} />
          ))}
          {cookies && cookies.logged_in && (
            <Button
              buttonText="Logout"
              classNames="logout"
              onClick={logoutUser}
            />
          )}
        </div>
        <SiteMenuFooter />
      </div>
      <Backdrop show={toggle} handleClick={() => handleToggle(false)} />
    </>
  );
}
