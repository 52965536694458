// import { Redirect, usePreloader, useRedirect } from "src/hooks";
// import { useCreateRoomMutation } from "src/redux/api/room.api";
import { Button, Form, FormFooter, Input, Wrapper } from "src/ui/components";
import { Buttons } from "src/ui/features";
import { defaultValues, validationSchema } from "./AddChatRoom.schema";
import "./AddChatRoom.styles.scss";
import AddChatRoomProps from "./AddChatRoom.types";

export default function AddChatRoomForm({ handleShowModal }: AddChatRoomProps) {
  // const [createRoom, { isLoading, isSuccess }] = useCreateRoomMutation();
  // const isPreloaderSuccess = isLoading && !isSuccess;
  // const isRedirectSuccess = !isLoading && isSuccess;
  const onSubmit = (data: any) => {
    // createRoom(data.chatRoom);
  };
  // usePreloader(isPreloaderSuccess);
  // useRedirect(isRedirectSuccess, Redirect.CHAT);

  return (
    <Wrapper classNames="addChatRoom" dataTestId="addChatRoomForm">
      <Form
        classNames="addChatRoomForm"
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        validation={validationSchema}
      >
        <Input name="chatRoom" placeholder="Chat Room" inputType="text" />
        <FormFooter classNames="form">
          <div>
            <Button
              buttonText="Cancel"
              classNames="link"
              onClick={handleShowModal}
            ></Button>
          </div>
          <div>
            <Buttons.Button classNames="addChatRoom" buttonText="Add" />
          </div>
        </FormFooter>
      </Form>
    </Wrapper>
  );
}
