import { useMemo } from "react";
import { SplashTheme } from "src/types/IUi.types";
import { ReactComponent as AxeMurderer } from "src/assets/memes/duckbois/axeMurderer.svg";
import { ReactComponent as Bullish } from "src/assets/memes/duckbois/bullish.svg";
import { ReactComponent as Droog } from "src/assets/memes/duckbois/droog.svg";
import { ReactComponent as FalseIdol } from "src/assets/memes/duckbois/falseidol.svg";
import { ReactComponent as FastFoodJunky } from "src/assets/memes/duckbois/fastfoodJunky.svg";
import { ReactComponent as GameBoy } from "src/assets/memes/duckbois/gameboi.svg";
import { ReactComponent as Grunge } from "src/assets/memes/duckbois/grunge.svg";
import { ReactComponent as Hoodlum } from "src/assets/memes/duckbois/hoodlum.svg";
import { ReactComponent as Jerkoff } from "src/assets/memes/duckbois/jerkoff.svg";
import { ReactComponent as Looney } from "src/assets/memes/duckbois/loon.svg";
import { ReactComponent as MetalHead } from "src/assets/memes/duckbois/metalhead.svg";
import { ReactComponent as MovieBuff } from "src/assets/memes/duckbois/moviebuff.svg";
import { ReactComponent as Og } from "src/assets/memes/duckbois/og.svg";
import { ReactComponent as Popcat } from "src/assets/memes/duckbois/popcat.svg";
import { ReactComponent as Punkass } from "src/assets/memes/duckbois/punkass.svg";
import { ReactComponent as Redneck } from "src/assets/memes/duckbois/redneck.svg";
import { ReactComponent as SkateRat } from "src/assets/memes/duckbois/skaterat.svg";
import { ReactComponent as Slasher } from "src/assets/memes/duckbois/dreamSlasher.svg";
import { ReactComponent as Brat } from "src/assets/memes/duckbois/brat.svg";
import { ReactComponent as SweetTooth } from "src/assets/memes/duckbois/sweettooth.svg";
import { ReactComponent as Spoiled } from "src/assets/memes/duckbois/spoiled.svg";
import { ReactComponent as Trash } from "src/assets/memes/duckbois/trash.svg";

export default function useRng() {
  const svgs = useMemo(
    () => [
      { theme: SplashTheme.AXE_MURDERER, svg: <AxeMurderer /> },
      { theme: SplashTheme.BRAT, svg: <Brat /> },
      { theme: SplashTheme.BULLISH, svg: <Bullish /> },
      { theme: SplashTheme.DROOG, svg: <Droog /> },
      { theme: SplashTheme.FALSE_IDOL, svg: <FalseIdol /> },
      { theme: SplashTheme.FASTFOODJUNKY, svg: <FastFoodJunky /> },
      { theme: SplashTheme.GAME_BOY, svg: <GameBoy /> },
      { theme: SplashTheme.GRUNGE, svg: <Grunge /> },
      { theme: SplashTheme.HOODLUM, svg: <Hoodlum /> },
      { theme: SplashTheme.JERKOFF, svg: <Jerkoff /> },
      { theme: SplashTheme.LOONEY, svg: <Looney /> },
      { theme: SplashTheme.METALHEAD, svg: <MetalHead /> },
      { theme: SplashTheme.MOVIE_BUFF, svg: <MovieBuff /> },
      { theme: SplashTheme.OG, svg: <Og /> },
      { theme: SplashTheme.POPCAT, svg: <Popcat /> },
      { theme: SplashTheme.PUNKASS, svg: <Punkass /> },
      { theme: SplashTheme.REDNECK, svg: <Redneck /> },
      { theme: SplashTheme.SKATERAT, svg: <SkateRat /> },
      { theme: SplashTheme.SLASHER, svg: <Slasher /> },
      { theme: SplashTheme.SWEETTOOTH, svg: <SweetTooth /> },
      { theme: SplashTheme.SPOILED, svg: <Spoiled /> },
      { theme: SplashTheme.TRASH, svg: <Trash /> },
    ],
    []
  );
  return {
    svgs,
  };
}
