import { Forms } from "src/ui/features";
import { Layouts } from "src/ui/pages/components";

export default function LoginPage() {
  return (
    <Layouts.Simple>
      <Forms.ForgotPasswordForm />
    </Layouts.Simple>
  );
}
