import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

function ScrollToAnchor({ callback }: { callback: any }) {
  const location = useLocation();
  const lastHash = useRef("");
  // listen to location change using useEffect with location as dependency
  // https://jasonwatmore.com/react-router-v6-listen-to-location-route-change-without-history-listen
  // ARTICLE: https://dev.to/mindactuate/scroll-to-anchor-element-with-react-router-v6-38op
  useEffect(() => {
    console.log("location.hash", location.hash);
    if (location.hash) {
      lastHash.current = location.hash.slice(1); // safe hash for further use after navigation
    }

    if (lastHash.current && document.getElementById(lastHash.current)) {
      const timout = setTimeout(() => {
        document
          .getElementById(lastHash.current)
          ?.scrollIntoView({ behavior: "smooth", block: "start" });
        lastHash.current = "";
        callback();
      }, 100);
      return () => clearTimeout(timout);
    }
  }, [location]);

  return null;
}

export default ScrollToAnchor;
