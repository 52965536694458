import { ContactModalProps } from "./Tos.types";
import { Modal } from "src/ui/components";
import "./Tos.styles.scss";

export default function ContactModal({
  contactIcon,
  handleShowModal,
  showModal,
  size,
}: ContactModalProps) {
  return showModal ? (
    <Modal classNames="contactModal" data-testid="contactModal">
      <Modal.Header
        handleClick={handleShowModal}
        icon={contactIcon}
        size={size}
      ></Modal.Header>
      <Modal.Body>
        <div>
          *DUCKBOI is a meme token with no intrinsic value or expectation of
          financial return. The token is for entertainment purposes only.
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  ) : null;
}
