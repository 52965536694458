import DropdownMenuProps from "./DropdownMenu.types";
import "./DropdownMenu.styles.scss";
import { Link } from "react-router-dom";
import { MENU_TYPES } from "../Table/Table.constants";

export default function DropdownMenu({
  classNames,
  closeMenu,
  menuItems,
  menuType,
  onClick,
  toggle,
}: DropdownMenuProps) {
  const className = classNames ? `dropdownMenu ${classNames}` : "dropdownMenu";
  return toggle ? (
    <div className={className} data-testid="dropdownMenu">
      <ul>
        {menuItems.map(
          (
            {
              callback,
              isClose = false,
              name,
              to,
            }: {
              callback?: () => void;
              isClose?: Boolean;
              name: string;
              to?: string;
            },
            index: number
          ) => {
            if (menuType === MENU_TYPES.CONTEXT) {
              if (isClose) {
                return (
                  <li key={index} onClick={closeMenu}>
                    {name}
                  </li>
                );
              }
              if (to) {
                return (
                  <li key={index}>
                    <Link to={to}>{name}</Link>
                  </li>
                );
              }
              if (callback) {
                return (
                  <li key={index} onClick={callback}>
                    {name}
                  </li>
                );
              }
            }

            if (menuType === MENU_TYPES.FILTER) {
              return (
                <li key={index} onClick={() => onClick && onClick(name)}>
                  {name}
                </li>
              );
            }

            return null;
          }
        )}
      </ul>
    </div>
  ) : null;
}
