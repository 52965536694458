import {
  faDiscord,
  faInstagram,
  faRedditAlien,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";

export const SOCIAL_MENU = [
  {
    icon: faXTwitter,
    href: "https://x.com/duckboi888",
  },
  {
    icon: faDiscord,
    href: "https://discord.gg/cfyhCsv5YT",
  },
  {
    icon: faInstagram,
    href: "https://www.instagram.com/duckboi84",
  },
  {
    icon: faRedditAlien,
    href: "https://www.reddit.com/user/_duckboi/",
  },
];
