import Footer from "./Footer";
import Duckboi from "./Duckboi";
import Logo from "./Logo";
import Monogram from "./Monogram";
export const Spoof = {
  Duckboi,
  Logo,
  Monogram,
  Footer,
};
